import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, map, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { User } from "../../classes/user";
import { GlobalErrorHandler } from '../../_services/error-handler.service';



@Injectable()
export class UserService {

    constructor(private http: HttpClient, private errorService: GlobalErrorHandler) {
    }

    getUserInformation(token): Observable<any> {
        return this.http.get('/api/getUserInformation/' + token, this.jwt()).pipe(map((response) => response));
    }
    changeChartVisibility(chart): Observable<any> {

        return this.http.post('/api/changeVisibility', JSON.stringify({ chart }), this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }
    ChartVisibilitySettings(email): Observable<any> {
        return this.http.post('/api/ChartVisibilitySettings', JSON.stringify({ email }), this.jwt()).pipe(map((response) => response));
    }

    verify(): Observable<any> {
        return this.http.get('/api/validate', this.jwt()).pipe(map((response) => response));
    }

    getUsersList(): Observable<any> {
        return this.http.get("/api/usersList", this.jwt()).pipe(map((response) => response));
    }

    forgotPasswordOTP(email: string): Observable<any> {
        return this.http.post('/api/forgetpasswordOTP', { email }).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }

    loginOTP(email: string): Observable<any> {
        return this.http.post('/api/loginOTP', { email }).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }

    forgotPassword(data): Observable<any> {
        return this.http.post('/api/forgetpassword', data).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }



    getAll(): Observable<any> {
        return this.http.get('/api/users', this.jwt()).pipe(map((response) => response));
    }

    getById(id: number): Observable<any> {
        return this.http.get('/api/user/' + id, this.jwt()).pipe(map((response) => response));
    }

    create(user: User): Observable<any> {
        return this.http.post('/api/signup', user).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }
    assignMunit(data): Observable<any> {
        return this.http.post('/api/AssignMunit/', data, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }
    update(user: User): Observable<any> {
        return this.http.post('/api/updateUser/', user, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }

    delete(id: number): Observable<any> {
        return this.http.delete('/api/deleteUser/' + id, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }
    changePassword(passData): Observable<any> {
        return this.http.post('/api/changeUserPassword', JSON.stringify({ passData }), this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }
    matchPassword(currentPassword): Observable<any> {
        return this.http.post('/api/MatchPassword', JSON.stringify({ currentPassword }), this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }
    
    ActivateUserById(id): Observable<any> {
        return this.http.post('/api/ActivateUserForLoginAttempts/', {id}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        ),
            map((response) => response));
    }
    
    getLoggingList(): Observable<any> {
        return this.http.get('/api/getAllLogging', this.jwt()).pipe(map((response) => response));
    }

    //2.0
    getUserLogging(): Observable<any> {
        return this.http.get('/api/getUserLogging', this.jwt()).pipe(map((response) => response));
    }
    
    changeNewUserPassword(otp ,password): Observable<any> {
        return this.http.post('/api/changeNewUserPassword', {otp, password}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        )
            , map((response) => response));
    }
    forgetpasswordOTP(){
        return this.http.post('/api/create_forget_otp', {}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        )
            , map((response) => response));
    }

    UpdateTesterProjectandRoles(data):Observable<any>{
        return this.http.post('/api/testerProjectRoles', data, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        )
            , map((response) => response));
    }

    // private helper methods

    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + currentUser.token,
                'Cache-Control': 'no-cache'
            });
            return { headers: headers };
        }
    }
}