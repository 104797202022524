import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "./_services/script-loader.service";
import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { AuthModule } from "./auth/auth.module";
import { FormsModule } from '@angular/forms';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { GlobalEventsManagerService } from './global-event-manager-service.service';
import { DatePipe } from '@angular/common';
import {ReportService} from './_services/report.service';
import {IssuesService} from './_services/Issues.service';
import { HttpClientXsrfModule } from '@angular/common/http';
// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

@NgModule({
    declarations: [
        ThemeComponent,
        AppComponent,
        
    ],
    imports: [
        // FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ThemeRoutingModule,
        AuthModule,
        FormsModule,
        Ng2IziToastModule,   
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
          })
        
    ],
    providers: [ScriptLoaderService, GlobalEventsManagerService],
    bootstrap: [AppComponent]
})
export class AppModule { }