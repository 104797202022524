import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from "rxjs/Observable";

@Injectable()
export class GlobalEventsManagerService {

    private _showNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private _showSuperAdminBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private _showTesterBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private _showManagerData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private _showMemberData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private _showPentesterData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private _chartEventData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _assetRiskchartEventData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _barChartEventData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private _filterImpactTypeBarIssues: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _lineChartEventData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _CVSSChartEventData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _selectedMunits: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _setCompanyRisk: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _refreshAlertMessages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _mapEventData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    private _logindata: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public showNavBarEmitter: Observable<boolean> = this._showNavBar.asObservable();
    public showSuperAdminBarEmitter: Observable<boolean> = this._showSuperAdminBar.asObservable();
    public showTesterBarEmitter: Observable<boolean> = this._showTesterBar.asObservable();
    public chartEventEmitter: Observable<any> = this._chartEventData.asObservable();
    public assetRiskchartEventEmitter: Observable<any> = this._assetRiskchartEventData.asObservable();
    public mapEventEmitter: Observable<any> = this._mapEventData.asObservable();
    public CVSSChartEventEmitter: Observable<any> = this._CVSSChartEventData.asObservable();
    public barChartEventEmitter: Observable<any> = this._barChartEventData.asObservable();
	public barChartImpactTypeEvenEmitter: Observable<any> = this._filterImpactTypeBarIssues.asObservable();
    public lineChartEventEmitter: Observable<any> = this._lineChartEventData.asObservable();

    public showMemberEmitter: Observable<boolean> = this._showMemberData.asObservable();

    public showManegerEmitter: Observable<boolean> = this._showManagerData.asObservable();
    public showPentesterEmitter: Observable<boolean> = this._showPentesterData.asObservable();
    public loginDataEmitter: Observable<any> = this._logindata.asObservable();
    public selectedMunitsEmitter: Observable<any> = this._selectedMunits.asObservable();
    public companyRiskEmitter: Observable<any> = this._setCompanyRisk.asObservable();
    public alertMessagesEmitter: Observable<any> = this._refreshAlertMessages.asObservable();

    constructor() { }

    showNavBar(ifShow: boolean) {
        this._showNavBar.next(ifShow);
    }
    showSuperAdminBar(ifShow: boolean) {
        this._showSuperAdminBar.next(ifShow);
    }
    showTesterBar(ifShow: boolean) {
        this._showTesterBar.next(ifShow);
    }
    showMemberData(ifShow: boolean) {
        this._showMemberData.next(ifShow);
    }
    handleChartClick(event, field, chart) {
        this._chartEventData.next({ event, field, chart });
    }
    handleBarChartClick(event, field, chart) {
        this._barChartEventData.next({ event, field, chart });
    }
	handleImpactTypeBarChartClick(event, field, chart) {
        this._filterImpactTypeBarIssues.next({ event, field, chart });
    }
    handleLineChartClick(event, field, chart) {
        this._lineChartEventData.next({ event, field, chart });
    }
    handleCVSSChartClick(event, field) {
        this._CVSSChartEventData.next({ event, field });
    }
    handleAssetRiskChartClick(event, field, chart) {
        this._assetRiskchartEventData.next({ event, field, chart });
    }
    showManagerData(ifShow: boolean) {
        this._showManagerData.next(ifShow);
    }

    showPentesterData(ifShow: boolean) {
        this._showPentesterData.next(ifShow);

    }
    setLoginData(loginData: any) {
        this._logindata.next(loginData);
    }
    setSelectedMunitsData(munits: any) {
        this._selectedMunits.next(munits);
    }
    setCompanyRisk(risk: any) {
        this._setCompanyRisk.next(risk);
    }
    refreshAlertMessages(shouldGet: any) {
        this._refreshAlertMessages.next(shouldGet);
    }
    handleMapClick(event, map) {
        this._mapEventData.next({ event, map });
    }
}


