import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, map, take, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { GlobalErrorHandler } from '../../_services/error-handler.service';

@Injectable()
export class BrowserAuthService {
    

    constructor(private http: HttpClient,private errorService:GlobalErrorHandler) {
    }

    registerNewBrowser(otp) {

        // remove user from local storage to log user out
        return this.http.post("/api/register_new_browser", {otp}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        )
        ,map((response) => response));
        

    }

    sendOTPForNewBrowser(otp) {
        
        // remove user from local storage to log user out
        return this.http.post("/api/create_new_browser_otp",{otp}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        )
            , map((response) => response));
    }

    
    verifyBrowser(){

        // remove user from local storage to log user out
        return this.http.get("/api/check_browser", this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        )
            , map((response) => response));
    }

    removeBrowser(browserUUID){

        // remove user from local storage to log user out
        return this.http.post("/api/delete_browser",{browserUUID}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        )
            , map((response) => response));
    }

    getAllUserBrowsers(){
        // remove user from local storage to log user out
        return this.http.get("/api/user_all_browsers", this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        )
            , map((response) => response));
    }

    refreshJWT(){
        return this.http.post('api/refreshjwt', {}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
        )
            , map((response) => {
                // login successful if there's a jwt token in the response

                let user: any = response;
                if (user.error&&user.error.message ) {

                    localStorage.removeItem('currentUser');
                    localStorage.setItem('isUserLoggedIn', 'false');
                    return user

                } else if (user && user.token) {
                    //clear storage
                    localStorage.removeItem('currentUser');
                    localStorage.removeItem('isUserLoggedIn');
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('isUserLoggedIn', 'true');
                    return "ok"
                }

            }));
    }

    
    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (currentUser && currentUser.token) {

            let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + currentUser.token });
            return { headers: headers };
        }
    }
}