import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";
import { Helpers } from "../../helpers";
import { GlobalEventsManagerService } from '../../global-event-manager-service.service';



@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class LogoutComponent implements OnInit {

    constructor(private _router: Router,
        private _authService: AuthenticationService, private _globalEventService: GlobalEventsManagerService) {
    }

    ngOnInit(): void {
        Helpers.setLoading(true);
        // reset login status
        this._authService.logout().subscribe(res=>{
            console.log("logged out");
            
        })
        this.subscribeToAuthentication();
        this._router.navigate(['/login']);


    }
    subscribeToAuthentication() {
        this._globalEventService.showNavBar(false);


        this._globalEventService.showManagerData(false);


        this._globalEventService.showMemberData(false);
     
        
    }

}