import { Observable, of } from "rxjs";
import { Helpers } from '../helpers';
import { unsupported } from '@angular/compiler/src/render3/view/util';
export class IssuesHelper {
    public filteredIssues = [];
    public ips = [];
    public sliceIPs = [];
    public BarIPs = [];
    public HBLR = 0;
    public HBMR = 0;
    public HBHR = 0;
    public MBLR = 0;
    public MBMR = 0;
    public MBHR = 0;
    public LBLR = 0;
    public LBMR = 0;
    public LBHR = 0;
    
    dashboardDataFormulation(issues,projectSettings,projectName){
       

        this.HBLR = 0;
        this.HBMR = 0;
        this.HBHR = 0;
        this.MBLR = 0;
        this.MBMR = 0;
        this.MBHR = 0;
        this.LBLR = 0;
        this.LBMR = 0;
        this.LBHR = 0;
        let ips = [];
        let uniqueIps = [];
        const WordsWithFreq = {};
        const WordsWithFreqTestType = {}
        let OwaspArray = [];
        let owaspdata = {};
        let riskTable = [];
        const WordsWithFreqTop20HighRR = {};
        const WordsWithFreqActor = {};
        const WordsWithFreqVector = {};
        const WordsWithFreqCIA = {};
        const WordsWithFreqSev = {};        
        const WordsWithFreqTop10Cat = {};
        let TypeTop20RR = {
            'customer_relationship_impact': 3,
            'Financial Impact': 3,
            'internal_operations_impact': 2,
            'legal/regulatory_impact': 3,
            'environmental_impact': 2,
            'human_health_impact': 3,
            'company_image_impact': 3
        }
        let vulnerability_ratingTop20RR = {
            'low': 1, 'medium': 2, 'critical': 3
        };
        let riskTableTop20RR = [];
        let assetsInFilterTop20RR = 0;
        let companyTop20BI = { 'Banks': 1, 'NGO': 2, 'SmallBusinesses': 3 };
        let TypeTop20BI = {
            'customer_relationship_impact': 3,
            'Financial Impact': 3,
            'internal_operations_impact': 2,
            'legal/regulatory_impact': 3,
            'environmental_impact': 2,
            'human_health_impact': 3,
            'company_image_impact': 3
        }
        let vulnerability_ratingTop20BI = {
            'low': 1, 'medium': 2, 'critical': 3
        };
        let riskTableTop20BI = [];
        let assetsInFilterTop20BI = 0;
        const WordsWithFreqTop20HBI = {};
        const ipdataMVS=[];
        let  SLAARRAY = {
            Low: 0,
            Medium: 0,
            Critical: 0
        }
        let cvssArray = [];
        let cvssdata = {};
        let issueStatusObject = {};
        let IssueStatusArray = [];
        
        const resultArrayTestType = [];
        const resultArrayVector = [];
        const resultArrayCIA = [];
        const resultArraySev = [];
        const resultArrayTop10Cat = [];
        const resultArrayTop20RR = [];
        const resultArrayTop20HighRR = [];
        const resultArrayTop20HBI = [];
        var sortableMVS = [];
        var sortableSLA = [];
        const resultArrayTop20BI = [];
        let avgRisk;
        let issueStatus;
        let CVSS;
        let OWASP;
        const resultArrayOS = [];
        let BusinessImpactGraphData={};
        const resultArrayActor = [];
        let Issues = {};
        let timeline = null;//this.timelineCal(issues);
        

        if(issues && issues.length>0){
            //  SLA ARRAY STARTS
         
            let timeSLA = {
                Low: 0,
                Medium: 0,
                Critical: 0
            }
            if (projectSettings){
                projectSettings.forEach(_sla => {
                    // bad approach must make new function for dates
                    if (_sla.slaTime === "Minutes")
                        timeSLA[_sla.name] = new Date(_sla.slaTimeValue * 60 * 1000).valueOf()
                    if (_sla.slaTime === "Hours")
                        timeSLA[_sla.name] = new Date(_sla.slaTimeValue * 60 * 60 * 1000).valueOf()
                    else if (_sla.slaTime === "Days")
                        timeSLA[_sla.name] = new Date(_sla.slaTimeValue * 24 * 60 * 60 * 1000).valueOf()
        
                })
            }
            //  SLA ARRAY ENDS      
            
            for(var i=0; i<issues.length;i++){
                
                // STATUS ARRAY STARTS
                    
                if (issues[i].assignee == null) {
                    let nullOs = "NullAssignee";

                    if (issueStatusObject.hasOwnProperty(nullOs))
                        issueStatusObject[nullOs] = issueStatusObject[nullOs] + 1;
                    else
                        issueStatusObject[nullOs] = 1;
                } else if (issues[i].assignee !== null) {
                    let status = "haveAssignee";

                    if (issueStatusObject.hasOwnProperty(status))
                        issueStatusObject[status] = issueStatusObject[status] + 1;
                    else
                        issueStatusObject[status] = 1;
                }

                if (issues[i].status == null) {
                    let nullstatus = "NullStatus";

                    if (issueStatusObject.hasOwnProperty(nullstatus))
                        issueStatusObject[nullstatus] = issueStatusObject[nullstatus] + 1;
                    else
                        issueStatusObject[nullstatus] = 1;
                } else if (issues[i].status !== null) {
                    let issuestatus = issues[i].status;

                    if (issueStatusObject.hasOwnProperty(issuestatus))
                        issueStatusObject[issuestatus] = issueStatusObject[issuestatus] + 1;
                    else
                        issueStatusObject[issuestatus] = 1;
                }
                // STATJS ARRAY ENDS
                // MostVulnerableSYS ARRAY STARTS
                const itemsMVS =  issues[i].ip;
                if (ipdataMVS.hasOwnProperty(itemsMVS))
                    ipdataMVS[itemsMVS] = ipdataMVS[itemsMVS] + 1;
                else
                    ipdataMVS[itemsMVS] = 1;
                // MostVulnerableSYS ARRAY ENDS
                
                if (issues[i].status.toLowerCase() !== 'done' && issues[i].status.toLowerCase() !== 'risk accepted'){
                    // SLA ARRAY STARTS
                    if (issues[i].updated && issues[i].priority) {

                        Object.keys(SLAARRAY).forEach(key => {
                            if (issues[i].customfield_10052.toLowerCase() === key.toLowerCase()) {
                                var t = new Date().valueOf() - new Date(issues[i].updated).valueOf()
                                if (t > timeSLA[key]) {
                                    SLAARRAY[key] += 1;
                                }
                            }
        
                        })
        
                    }
                    // SLA ARRAY ENDS
                    // Top20HighBusinessImpact ARRAY STARTS 
                    if(issues[i].customfield_10042){
                        let BusinessImpactTop20HBI = "" + issues[i].customfield_10042;
                        let wordFreqTop20HBI = WordsWithFreqTop20HBI[BusinessImpactTop20HBI.toLowerCase()];
                        if (wordFreqTop20HBI) {
                            wordFreqTop20HBI.count = ++wordFreqTop20HBI.count;
                        } else {
                            WordsWithFreqTop20HBI[BusinessImpactTop20HBI.toLowerCase()] = {
                                count: 1,
                                word: BusinessImpactTop20HBI
                            };
                        }
                    }                      


                    // Top20HighBusinessImpact ARRAY ENDS

                    // Top20HighRiskRate ARRAY STARTS
                        if(issues[i].customfield_10053 && issues[i].customfield_10044 && issues[i].customfield_10052){
                            let impactTypeKeyTop20BI = issues[i].customfield_10053;
                            let cvssTop20BI = issues[i].customfield_10044;
                            let v_ratingTop20BI = issues[i].customfield_10052;
                            impactTypeKeyTop20BI = impactTypeKeyTop20BI.replace(/ /g, "_").toLowerCase();
                            var impactValueTop20BI = 0, ciaValueTop20BI = 0, v_ratingValueTop20BI = 0;
                            Object.keys(TypeTop20BI).forEach(key => {
                                if (key == impactTypeKeyTop20BI) {
                                    impactValueTop20BI = TypeTop20BI[key];
                                    assetsInFilterTop20BI++;
                                }
                            });
                            ciaValueTop20BI = 1;
                            Object.keys(vulnerability_ratingTop20BI).forEach(key => {

                                if (key === v_ratingTop20BI.toLowerCase()) {
                                    v_ratingValueTop20BI = vulnerability_ratingTop20BI[key];
                                }
                            });

                            let assetTop20BI = (ciaValueTop20BI * impactValueTop20BI);
                            let threatTop20BI = cvssTop20BI;
                            let vulnerabilityTop20BI = v_ratingValueTop20BI;
                            let BusinessImpactRatingTop20BI = (assetTop20BI * companyTop20BI.Banks * threatTop20BI); // 1 is default value used for Banks, Telecom and Insurance Companies
                            riskTableTop20BI.push({
                                businessImpactRating: BusinessImpactRatingTop20BI,
                            });
                        }
                    // Top20HighRiskRate ARRAY ENDS
                    // Top20HighRiskRate ARRAY STARTS
                        if(issues[i].customfield_10043){
                            let riskRateTop20HighRR = "" + issues[i].customfield_10043;
                            let wordFreqTop20HighRR = WordsWithFreqTop20HighRR[riskRateTop20HighRR.toLowerCase()];
                            if (wordFreqTop20HighRR) {
                                wordFreqTop20HighRR.count = ++wordFreqTop20HighRR.count;
                            } else {
                                WordsWithFreqTop20HighRR[riskRateTop20HighRR.toLowerCase()] = {
                                    count: 1,
                                    word: riskRateTop20HighRR
                                };
                            }
                        }
                    // Top20HighRiskRate ARRAY ENDS
                    // Top20RiskRate ARRAY STARTS
                    if(issues[i].customfield_10053 && issues[i].customfield_10044 && issues[i].customfield_10052){
                        let impactTypeKeyTop20RR = issues[i].customfield_10053;
                        let cvssTop20RR = issues[i].customfield_10044;
                        let v_ratingTop20RR = issues[i].customfield_10052;
                        impactTypeKeyTop20RR = impactTypeKeyTop20RR.replace(/ /g, "_").toLowerCase();
                        var impactValueTop20RR = 0, ciaValueTop20RR = 0, v_ratingValueTop20RR = 0;
                        Object.keys(TypeTop20RR).forEach(key => {
                            if (key == impactTypeKeyTop20RR) {
                                impactValueTop20RR = TypeTop20RR[key];
                                assetsInFilterTop20RR++;
                            }
                        });
                        ciaValueTop20RR = 1;
                        Object.keys(vulnerability_ratingTop20RR).forEach(key => {

                            if (key === v_ratingTop20RR.toLowerCase()) {
                                v_ratingValueTop20RR = vulnerability_ratingTop20RR[key];
                            }
                        });

                        let assetTop20RR = (ciaValueTop20RR * impactValueTop20RR);
                        let threatTop20RR = cvssTop20RR;
                        let vulnerabilityTop20RR = v_ratingValueTop20RR;
                        let RiskAssesmetRatingTop20RR = (assetTop20RR * threatTop20RR * vulnerabilityTop20RR);
                        riskTableTop20RR.push({
                            riskAssesmetRating: RiskAssesmetRatingTop20RR
                        });
                    }
                    // Top20RiskRate ARRAY ENDS
                    // TOP10CATEGORIES ARRAY STARTS
                        if(issues[i].customfield_10059){
                            let technologyTop10Cat = issues[i].customfield_10059;
                            let wordFreqTop10Cat = WordsWithFreqTop10Cat[technologyTop10Cat.toLowerCase()];
                            if (wordFreqTop10Cat) {
                                wordFreqTop10Cat.count = ++wordFreqTop10Cat.count;
                            } else {
                                WordsWithFreqTop10Cat[technologyTop10Cat.toLowerCase()] = {
                                    count: 1,
                                    word: technologyTop10Cat
                                };
                            }
                        }
                    // TOP10CATEGORIES ARRAY ENDS
                    // VulnerabilitiesBySeverity ARRAY STARTS
                        if(issues[i].customfield_10052){
                            let severitySev = issues[i].customfield_10052;
                            let wordFreqSev = WordsWithFreqSev[severitySev.toLowerCase()];
                            if (wordFreqSev) {
                                wordFreqSev.count = ++wordFreqSev.count;
                            } else {
                                WordsWithFreqSev[severitySev.toLowerCase()] = {
                                    count: 1,
                                    word: severitySev
                                };
                            }
                        }
                    // VulnerabilitiesBySeverity ARRAY ENDS
                    // CIA ARRAY STARTS 
                        if(issues[i].customfield_10056){
                            let impactCIA = issues[i].customfield_10056;
                            let wordFreqCIA = WordsWithFreqCIA[impactCIA.toLowerCase()];
                            if (wordFreqCIA) {
                                wordFreqCIA.count = ++wordFreqCIA.count;
                            } else {
                                WordsWithFreqCIA[impactCIA.toLowerCase()] = {
                                    count: 1,
                                    word: impactCIA
                                };
                            }
                        }
                    // CIA ARRAY ENDS 
                    // Vector ARRAY STARTS
                        if(issues[i].customfield_10054){
                            let impactVector = issues[i].customfield_10054;
                            let wordFreqVector = WordsWithFreqVector[impactVector.toLowerCase()];
                            if (wordFreqVector) {
                                wordFreqVector.count = ++wordFreqVector.count;
                            } else {
                                WordsWithFreq[impactVector.toLowerCase()] = {
                                    count: 1,
                                    word: impactVector
                                };
                            }
                        }
                    // Vector aRRAY ENDS
                    // Actor    ARRAY STARTS
                    if(issues[i].customfield_10055){
                        let impactActor = issues[i].customfield_10055;
                        let wordFreqActor = WordsWithFreq[impactActor.toLowerCase()];
                        if (wordFreqActor) {
                            wordFreqActor.count = ++wordFreqActor.count;
                        } else {
                            WordsWithFreqActor[impactActor.toLowerCase()] = {
                                count: 1,
                                word: impactActor
                            };
                        }
                    }
                    // Actor ARRAY ENDS
                    //  AverageRisk ARRAY STARTS
                    if(issues[i].customfield_10044 && issues[i].customfield_10052 && issues[i].customfield_10042){
                        //let ciaKey = issues[i].customfield_10056;
                    // let impactTypeKey = issues[i].customfield_10053;
                        //let v_rating = issues[i].customfield_10052;
                        let cvss = issues[i].customfield_10044;
                        let AssetsValue = issues[i].AssetsValue;
                        let BusinessImpact = issues[i].customfield_10042;
                        let insecurity = BusinessImpact * cvss * AssetsValue
                        riskTable.push({
                            insecurity: insecurity
                        });

                    }
                    //  AverageRisk ARRAY ENDS
                    // UNIQUE IP ARRAY STARTS
                        ips.push(issues[i].ip);
                        uniqueIps = Array.from(new Set(ips))
                    // UNIQUE IP ARRAY ENDS
                    
                    // TESTTYPE ARRAY STARTS
                        if(issues[i].customfield_10049){
                            let impactTestType = issues[i].customfield_10049;
                            let wordFreqTestType = WordsWithFreqTestType[impactTestType.toLowerCase()];
                            if (wordFreqTestType) {
                                wordFreqTestType.count = ++wordFreqTestType.count;
                            } else {
                                WordsWithFreqTestType[impactTestType.toLowerCase()] = {
                                    count: 1,
                                    word: impactTestType
                                };
                            }
                        }
                    // TESTTYPE ARRAY ENDS
                    //  CVSS DATA ORGANIZING STARTS
                        if (issues[i].customfield_10044 == null) {
                            let nullOs = "nullCVSS";

                            if (cvssdata.hasOwnProperty(nullOs))
                                cvssdata[nullOs] = cvssdata[nullOs] + 1;
                            else
                                cvssdata[nullOs] = 1;
                        } else if (issues[i].customfield_10044 !== null) {
                            let cvss = issues[i].customfield_10044;

                            if (cvssdata.hasOwnProperty(cvss))
                                cvssdata[cvss] = cvssdata[cvss] + 1;
                            else
                                cvssdata[cvss] = 1;
                        }
                    //  CVSS DATA ORGANIZING STARTS
                    //  OWASP DATA ORGANIZING STARTS
                        if (issues[i].customfield_10036 == null) {
                            let nullOs = "nullOWSP";

                            if (owaspdata.hasOwnProperty(nullOs))
                                owaspdata[nullOs] = owaspdata[nullOs] + 1;
                            else
                                owaspdata[nullOs] = 1;
                        } else if (issues[i].customfield_10036 !== null) {
                            let owsp = issues[i].customfield_10036;

                            if (owaspdata.hasOwnProperty(owsp))
                                owaspdata[owsp] = owaspdata[owsp] + 1;
                            else
                                owaspdata[owsp] = 1;
                        }
                    //  OWASP DATA ORGANIZING ENDS
                    //  OS DATA ORGANIZING STARTS
                        if(issues[i].customfield_10050){
                            let os = issues[i].customfield_10050;
                            let wordFreq = WordsWithFreq[os.toLowerCase()];
                            if (wordFreq) {
                                wordFreq.count = ++wordFreq.count;
                            } else {
                                WordsWithFreq[os.toLowerCase()] = {
                                    count: 1,
                                    word: os
                                };
                            }
                        }
                    //  OS DATA ORGANIZING ENDS
                    //  BI MATRIX DATA ORGANIZING STARTS
                        if (issues[i].customfield_10042 !== null && issues[i].customfield_10043 !== null){
                            // High Business Impact
                            if (issues[i].customfield_10042 >= 71 && issues[i].customfield_10043 >= 18) {
                                this.HBHR++;
                            }
                            if (issues[i].customfield_10042 >= 71 && issues[i].customfield_10043 >= 10 && issues[i].customfield_10043 <= 17.99) {
                                this.HBMR++;
                            }
                            if (issues[i].customfield_10042 >= 71 && issues[i].customfield_10043 >= 1 && issues[i].customfield_10043 <= 9.99) {

                                this.HBLR++;
                            }
                            // Medium Business Impact
                            if (issues[i].customfield_10042 >= 31 && issues[i].customfield_10042 <= 70 && issues[i].customfield_10043 >= 18) {
                                this.MBHR++;
                            }
                            if (issues[i].customfield_10042 >= 31 && issues[i].customfield_10042 <= 70 && issues[i].customfield_10043 >= 10 && issues[i].customfield_10043 <= 17.99) {
                                this.MBMR++;
                            }
                            if (issues[i].customfield_10042 >= 31 && issues[i].customfield_10042 <= 70 && issues[i].customfield_10043 >= 1 && issues[i].customfield_10043 <= 9.99) {

                                this.MBLR++;
                            }
                            // Low Business Impact
                            if ( issues[i].customfield_10042 <= 30 && issues[i].customfield_10043 >= 1 && issues[i].customfield_10043 <= 9.99) {
                                this.LBLR++;
                            }
                            if ( issues[i].customfield_10042 <= 30 && issues[i].customfield_10043 >= 10 && issues[i].customfield_10043 <= 17.99) {
                                this.LBMR++;
                            }
                            if ( issues[i].customfield_10042 <= 30 && issues[i].customfield_10043 >= 18 ) {
                                this.LBHR++;
                            }
                        }
                    //  BI MATRIX DATA ORGANIZING ENDS

                }
                
                
            }
            
            //  BI MATRIX DATA ORGANIZING STARTS
            
            BusinessImpactGraphData = {
                HBLR: this.HBLR, HBMR: this.HBMR, HBHR: this.HBHR,
                MBLR: this.MBLR, MBMR: this.MBMR, MBHR: this.MBHR,
                LBLR: this.LBLR, LBMR: this.LBMR, LBHR: this.LBHR
            }

            // return BusinessImpactGraphData;
            //  BI MATRIX DATA ORGANIZING ENDS
            //  OS CALCULATIONS STARTS
           
            Object.keys(WordsWithFreq).forEach(key => {
                resultArrayOS.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArrayOS.sort((a, b) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
           // return resultArrayOS;
            //  OS CALCULATIONS ENDS
            //  OWASP DATA CALCULATION STARTS
                for (let key in owaspdata) {
                    OwaspArray.push({ key: key.toString(), 'count': owaspdata[key] });
                }
        
                let BrokenAuthentication = 0, Injection = 0, SensitiveDataExposure = 0, XMLExternalEntities = 0,
                    BrokenAccesControl = 0, SystemMisconfiguration = 0, CrossSS = 0, insecureDes = 0, insufficientLogging = 0, UCWKV = 0;
                for (var i = 0; i < OwaspArray.length; i++) {
                    if (OwaspArray[i].key.toLowerCase() === "injection") {
                        Injection += OwaspArray[i].count;
                    } else if (OwaspArray[i].key.toLowerCase() === "broken authentication") {
                        BrokenAuthentication += OwaspArray[i].count;
                    } else if (OwaspArray[i].key.toLowerCase() === "sensitive data exposure") {
                        SensitiveDataExposure += OwaspArray[i].count;
                    } else if (OwaspArray[i].key.toLowerCase() === "xml external entities (xxe)") {
                        XMLExternalEntities += OwaspArray[i].count;
                    } else if (OwaspArray[i].key.toLowerCase() === "broken access control") {
                        BrokenAccesControl += OwaspArray[i].count;
                    } else if (OwaspArray[i].key.toLowerCase() === "security misconfiguration") {
                        SystemMisconfiguration += OwaspArray[i].count;
                    } else if (OwaspArray[i].key.toLowerCase() === "cross-site scripting (xss)") {
                        CrossSS += OwaspArray[i].count;
                    } else if (OwaspArray[i].key.toLowerCase() === "insecure deserialization") {
                        insecureDes += OwaspArray[i].count;
                    } else if (OwaspArray[i].key.toLowerCase() === "using components with known vulnerabilities") {
                        UCWKV += OwaspArray[i].count;
                    } else if (OwaspArray[i].key.toLowerCase() === "insufficient logging and monitoring") {
                        insufficientLogging += OwaspArray[i].count;
                    }
                }
               
                OWASP = {
                    BA: BrokenAuthentication,
                    INJ: Injection,
                    SDE: SensitiveDataExposure,
                    XEE: XMLExternalEntities,
                    BAC: BrokenAccesControl,
                    SMC: SystemMisconfiguration,
                    CSS: CrossSS,
                    IND: insecureDes,
                    INL: insufficientLogging,
                    UCWKV: UCWKV
                }
            // return OWASP
            //  OWASP DATA CALCULATION ENDS
            // TEST TYPE DATA CALCULATION STARTS
                
                Object.keys(WordsWithFreqTestType).forEach(key => {
                    resultArrayTestType.push({ word: key, count: WordsWithFreqTestType[key].count });
                });
                // sorting in descending order by count
                
                const sortedFreqTestType = resultArrayTestType.sort((a, b) => {
                    if (a.count > b.count) {
                        return -1;
                    }
                    if (a.count < b.count) {
                        return 1;
                    }
                });
                // return resultArrayTestType;
            // TEST TYPE DATA CALCULATION ENDS
            // CVSS DATA CALCULATION STARTS
                for (let key in cvssdata) {
                    cvssArray.push({ key: key.toString(), 'count': cvssdata[key] });
                }
                let one, two, three, four, five, six, seven, eight, nine, ten = 0;
        
                for (var i = 0; i < cvssArray.length; i++) {
                    if (cvssArray[i].key == "1") {
                        one = cvssArray[i].count;
                    } else if (cvssArray[i].key == "2") {
                        two = cvssArray[i].count;
                    } else if (cvssArray[i].key == "3") {
                        three = cvssArray[i].count;
                    } else if (cvssArray[i].key == "4") {
                        four = cvssArray[i].count;
                    } else if (cvssArray[i].key == "5") {
                        five = cvssArray[i].count;
                    } else if (cvssArray[i].key == "6") {
                        six = cvssArray[i].count;
                    } else if (cvssArray[i].key == "7") {
                        seven = cvssArray[i].count;
                    } else if (cvssArray[i].key == "8") {
                        eight = cvssArray[i].count;
                    } else if (cvssArray[i].key == "9") {
                        nine = cvssArray[i].count;
                    } else if (cvssArray[i].key == "10") {
                        ten = cvssArray[i].count;
                    }
                }
                
                 CVSS = {
                    One: one,
                    Two: two,
                    Three: three,
                    Four: four,
                    Five: five,
                    Six: six,
                    Seven: seven,
                    Eight: eight,
                    Nine: nine,
                    Ten: ten
                }
            // CVSS DATA CALCULATIONS ENDS
           // STATUSES DATA CALCULATIONS STARTS
                for (let key in issueStatusObject) {
                    IssueStatusArray.push({ key: key.toString(), 'count': issueStatusObject[key] });
                }
        
                let open = 0, closed = 0, unassigned = 0, assigned = 0, inprogress = 0, riskAccepted = 0, notFixed = 0, remediated = 0;
                //closed = done_count;
                for (var i = 0; i < IssueStatusArray.length; i++) {
                    if (IssueStatusArray[i].key == "To Do") {
                        open = IssueStatusArray[i].count;
                    } else if (IssueStatusArray[i].key == "Done") {
                        closed = IssueStatusArray[i].count;
                    } else if (IssueStatusArray[i].key == "In Progress") {
                        inprogress = IssueStatusArray[i].count;
                    } else if (IssueStatusArray[i].key == "Risk Accepted") {
                        riskAccepted = IssueStatusArray[i].count;
                    } else if (IssueStatusArray[i].key == "Not Fixed") {
                        notFixed = IssueStatusArray[i].count;
                    } else if (IssueStatusArray[i].key == "Remediated") {
                        remediated = IssueStatusArray[i].count;
                    }
                    else if (IssueStatusArray[i].key == "NullAssignee") {
                        unassigned = IssueStatusArray[i].count;
                    } else if (IssueStatusArray[i].key == "haveAssignee") {
                        assigned = IssueStatusArray[i].count;
                    }
                }
                
                issueStatus = {
                    Open: open,
                    Closed: closed,
                    InProgress: inprogress,
                    "RiskAccepted": riskAccepted,
                    Remediated: remediated,
                    "NotFixed": notFixed,
                    Unassigned: unassigned,
                    Assigned: assigned
                }
            // STATUSES DATA CALCULATIONS ENDS
            // AverageRisk DATA CALCULATION STARTS
           
                
                Array.prototype.sum = function (prop) {
                    var total = 0;
                    for (var i = 0, _len = this.length; i < _len; i++) {
                        total += this[i][prop];
                    }
                    return total;
                };
                avgRisk = (riskTable.sum('insecurity') / (riskTable.length*10));
               // return avgRisk || 0;
            // AverageRisk DATA CALCULATION ENDS

            // Actor DATA CALCULATION STARTS
                
                Object.keys(WordsWithFreqActor).forEach(key => {
                    resultArrayActor.push({ word: key, count: WordsWithFreqActor[key].count });
                });
                // sorting in descending order by count
                const sortedFreqActor = resultArrayActor.sort((a, b) => {
                    if (a.count > b.count) {
                        return -1;
                    }
                    if (a.count < b.count) {
                        return 1;
                    }
                });
             //   return resultArrayActor;
            // Actor DATA CALCULATION ENDS
            // Vector DATA CALCULATION STARTS
                

                Object.keys(WordsWithFreqVector).forEach(key => {
                    resultArrayVector.push({ word: key, count: WordsWithFreqVector[key].count });
                });
                // sorting in descending order by count
                const sortedFreqVector = resultArrayVector.sort((a, b) => {
                    if (a.count > b.count) {
                        return -1;
                    }
                    if (a.count < b.count) {
                        return 1;
                    }
                });
              //  return resultArrayVector;
            // Vector DATA CALCULATION ENDS
            // CIA DATA CALCULATION STARTS                

                Object.keys(WordsWithFreqCIA).forEach(key => {
                    resultArrayCIA.push({ word: key, count: WordsWithFreqCIA[key].count });
                });
                // sorting in descending order by count
                const sortedFreqCIA = resultArrayCIA.sort((a, b) => {
                    if (a.count > b.count) {
                        return -1;
                    }
                    if (a.count < b.count) {
                        return 1;
                    }
                });
                //return resultArrayCIA;
            // CIA DATA CALCULATION ENDS
            // VulnerabilitiesBySeverity DATA CALCULATION STARTS
                

                Object.keys(WordsWithFreqSev).forEach(key => {
                    resultArraySev.push({ word: key, count: WordsWithFreqSev[key].count });
                });
                // sorting in descending order by count
                const sortedFreqWithCountSev = resultArraySev.sort((a, b) => {
                    if (a.count > b.count) {
                        return -1;
                    }
                    if (a.count < b.count) {
                        return 1;
                    }
                });
                const sortedFreqSev = sortedFreqWithCountSev.sort((a, b) => {
                    return a.word < b.word ? -1 : 1; //sorting word alphabetically. 
                });
                //return resultArraySev;
            // VulnerabilitiesBySeverity DATA CALCULATION ENDS
            // Top10Category DATA CALCULATION STARTS
                
                Object.keys(WordsWithFreqTop10Cat).forEach(key => {
                    resultArrayTop10Cat.push({ word: key, count: WordsWithFreqTop10Cat[key].count });
                });
                // sorting in descending order by count
                const sortedFreqTop10Cat = resultArrayTop10Cat.sort((a, b) => {
                    if (a.count > b.count) {
                        return -1;
                    }
                    if (a.count < b.count) {
                        return 1;
                    }
                });
               // return resultArrayTop10Cat;
            // Top10Category DATA CALCULATION ENDS
            // Top20RiskRate DATA CALCULATION STARTS

                const WordsWithFreqTop20RR = {};

                riskTableTop20RR.forEach((risk, i) => {
                    if (risk) {
                        let wordFreqTop20RR = WordsWithFreq[risk.riskAssesmetRating];
                        if (wordFreqTop20RR) {
                            wordFreqTop20RR.count = ++wordFreqTop20RR.count;
                        } else {
                            WordsWithFreqTop20RR[risk.riskAssesmetRating] = {
                                risk: risk.riskAssesmetRating,
                                count: 1,
                            };
                        }
                    };
                });
                // iterating objects on the basis of key to convert into array.
                
                Object.keys(WordsWithFreqTop20RR).forEach(key => {
                    resultArrayTop20RR.push({ risk: WordsWithFreqTop20RR[key].risk, count: WordsWithFreqTop20RR[key].count });
                });
                const sortedFreqTop20RR = resultArrayTop20RR.sort((a, b) => {
                    if (a.risk > b.risk) {
                        return -1;
                    }
                    if (a.risk < b.risk) {
                        return 1;
                    }
                });
               // return resultArrayTop20RR;
            // Top20RiskRate DATA CALCULATION ENDS
            // Top20HighRiskRate DATA CALCULATION STARTS
                
                Object.keys(WordsWithFreqTop20HighRR).forEach(key => {
                    resultArrayTop20HighRR.push({ word: key, count: WordsWithFreqTop20HighRR[key].count });
                });
                // sorting in descending order by count
                const sortedFreqTop20HighRR = resultArrayTop20HighRR.sort((a, b) => {
                    if (a.count > b.count) {
                        return -1;
                    }
                    if (a.count < b.count) {
                        return 1;
                    }
                });
                //return resultArrayTop20HighRR;
            // Top20HighRiskRate DATA CALCULATION ENDS

            // Top20HighRiskRate DATA CALCULATION STARTS
                const WordsWithFreqTop20BI = {};

                riskTableTop20BI.forEach((risk, i) => {
                    if (risk) {
                        let wordFreqTop20BI = WordsWithFreqTop20BI[risk.businessImpactRating];
                        if (wordFreqTop20BI) {
                            wordFreqTop20BI.count = ++wordFreqTop20BI.count;
                        } else {
                            WordsWithFreqTop20BI[risk.businessImpactRating] = {
                                impact: risk.businessImpactRating,
                                count: 1,
                            };
                        }
                    };
                });
        
                // iterating objects on the basis of key to convert into array.
                
                Object.keys(WordsWithFreqTop20BI).forEach(key => {
                    resultArrayTop20BI.push({ impact: WordsWithFreqTop20BI[key].impact, count: WordsWithFreqTop20BI[key].count });
                });
                const sortedFreqTop20BI = resultArrayTop20BI.sort((a, b) => {
                    if (a.impact > b.impact) {
                        return -1;
                    }
                    if (a.impact < b.impact) {
                        return 1;
                    }
                });
        
            //return resultArrayTop20BI; 
            // Top20HighRiskRate DATA CALCULATION ENDS
            // Top20HighBusinessImpact DATA CALCULATION STARTS
                
                Object.keys(WordsWithFreqTop20HBI).forEach(key => {
                    resultArrayTop20HBI.push({ word: key, count: WordsWithFreqTop20HBI[key].count });
                });
                // sorting in descending order by count
                const sortedFreqTop20HBI = resultArrayTop20HBI.sort((a, b) => {
                    if (a.count > b.count) {
                        return -1;
                    }
                    if (a.count < b.count) {
                        return 1;
                    }
                });
                //return resultArrayTop20HBI;
            // Top20HighBusinessImpact DATA CALCULATION ENDS

            // MostVulnerableSYS DATA CALCULATION STARTS
                
                for (var ipMVS in ipdataMVS) {
                    sortableMVS.push([ipMVS, ipdataMVS[ipMVS]]);
                }
        
                sortableMVS.sort(function (a, b) {
                    return b[1] - a[1];
                });
        
                sortableMVS.slice(0, 5);
        
            //    return sortableMVS
            // MostVulnerableSYS DATA CALCULATION ENDS

            // SLA DATA CALCULATION STARTS
               
                for (var sla in SLAARRAY) {
                    sortableSLA.push([sla, SLAARRAY[sla]]);
                }
               // RETURN sortableSLA;
            // SLA DATA CALCULATION ENDS
            
        }
       // var response=issues;
        //let bi = this.BusinessImpactGraphData(response);
        //let uniqueIps = this.UniqueIPs(response);
        let osArray = resultArrayOS;
        let owasp = OWASP;
        let testType = resultArrayTestType;
        let cvss = CVSS;

       // let issueStatus = this.IssueStatus(response, issues.done.length);
       // let timeline = this.newTimeline([...response, ...issues.done]);
        let allIssues = issues;
		
        //let avgRisk = this.AverageRisk(response);

       
        let actor = resultArrayActor;
        let vector = resultArrayVector;
        let ciaDamage = resultArrayCIA;
        let severity = resultArraySev;
        let top10Category = resultArrayTop10Cat;
        let top20RiskRate = resultArrayTop20RR;
        let top20HighRiskRate = resultArrayTop20HighRR;
        let top20BusinessImpact = resultArrayTop20BI;
        let top20HighBusinessImpact = resultArrayTop20HBI;
        let mostVulnerableSystem = sortableMVS;
        let SLA = sortableSLA;
        let assetRisk = [];//IssuesHelper.assetRisk(response);
        
        
        
            Issues = {
                All: allIssues,
                ProjectName:projectName,
                Timeline: timeline,
                IssueStatus: issueStatus,
                VulnerabilityType: [],
                CVSS: CVSS,
                TestType: testType,
                OWASP: OWASP,
                OS: osArray,
                UniqueIps: uniqueIps,
                BI: BusinessImpactGraphData,
                Risk: avgRisk,
                Actor: actor,
                Vector: vector,
                CIADamage: ciaDamage,
                Severity: severity,
                top10Category: top10Category,
                top20RiskRate: top20RiskRate,
                top20BusinessImpact: top20BusinessImpact,
                Top20HighBusinessImpact: top20HighBusinessImpact,
                Top20HighRiskRate: top20HighRiskRate,
                mostVulnerableSystem: mostVulnerableSystem,
                SLA: SLA,
                AssetRisk:assetRisk
    
            }
        console.log(Issues);
        return Issues
    }
    BusinessImpactGraphData(issues) {

        this.HBLR = 0;
        this.HBMR = 0;
        this.HBHR = 0;
        this.MBLR = 0;
        this.MBMR = 0;
        this.MBHR = 0;
        this.LBLR = 0;
        this.LBMR = 0;
        this.LBHR = 0;
        for (let i = 0; i < issues.length; i++) {
            var type = issues[i].fields.labels.length;
            if (issues[i].fields !== "undefined" && issues[i].fields.customfield_10042 !== null && issues[i].fields.customfield_10043 !== null  && issues[i].status!='done' && issues[i].status!='Risk Accepted') {
                // High Business Impact
                if (issues[i].customfield_10042 >= 71 && issues[i].customfield_10043 >= 18) {
                    this.HBHR++;
                }
                if (issues[i].customfield_10042 >= 71 && issues[i].customfield_10043 >= 10 && issues[i].customfield_10043 <= 17.99) {
                    this.HBMR++;
                }
                if (issues[i].customfield_10042 >= 71 && issues[i].customfield_10043 >= 1 && issues[i].customfield_10043 <= 9.99) {

                    this.HBLR++;
                }
                // Medium Business Impact
                if (issues[i].customfield_10042 >= 31 && issues[i].customfield_10042 <= 70 && issues[i].customfield_10043 >= 18) {
                    this.MBHR++;
                }
                if (issues[i].customfield_10042 >= 31 && issues[i].customfield_10042 <= 70 && issues[i].customfield_10043 >= 10 && issues[i].customfield_10043 <= 17.99) {
                    this.MBMR++;
                }
                if (issues[i].customfield_10042 >= 31 && issues[i].customfield_10042 <= 70 && issues[i].customfield_10043 >= 1 && issues[i].customfield_10043 <= 9.99) {

                    this.MBLR++;
                }
                // Low Business Impact
                if ( issues[i].customfield_10042 <= 30 && issues[i].customfield_10043 >= 1 && issues[i].customfield_10043 <= 9.99) {
                    this.LBLR++;
                }
                if ( issues[i].customfield_10042 <= 30 && issues[i].customfield_10043 >= 10 && issues[i].customfield_10043 <= 17.99) {
                    this.LBMR++;
                }
                if ( issues[i].customfield_10042 <= 30 && issues[i].customfield_10043 >= 18 ) {
                    this.LBHR++;
                }
            }
        }
        let BusinessImpactGraphData = {
            HBLR: this.HBLR, HBMR: this.HBMR, HBHR: this.HBHR,
            MBLR: this.MBLR, MBMR: this.MBMR, MBHR: this.MBHR,
            LBLR: this.LBLR, LBMR: this.LBMR, LBHR: this.LBHR
        }
        return BusinessImpactGraphData;
    }

    UniqueIPs(issues) {
        let uniqueIps = [];
        let uniqueIpsCount = 0;
        this.ips = [];
        if (issues.length > 0) {
            for (let i = 0; i < issues.length; i++) {
                var type = issues[i].fields.labels.length;

                if (issues[i].fields.labels.length > 0) {
                    var items = issues[i].fields.labels[0];
                    if (items.indexOf('.') > -1) {
                        this.ips.push(items);
                        uniqueIps = Array.from(new Set(this.ips))
                        uniqueIpsCount = uniqueIps.length;
                    }
                }
            }
        }
        return uniqueIps;
    }

    mUnitIssues(issues, munit): Observable<any> {

        this.filteredIssues = [];
        for (var i = 0; i < issues.length; i++) {
            let issue = issues[i];
            for (var j = 0; j < issue.fields.labels.length; j++) {
                var label = issue.fields.labels[j];
                for (let k = 0; k < munit.length; k++) {

                    if (label && munit[k] && label.toLowerCase() == munit[k].toLowerCase()) {
                        this.filteredIssues.push(issue);
                    }
                }
            }
        }
        var issuess = this.filteredIssues;

        return of(this.filteredIssues);
    }

    filterDateIssues(issues, issueDate) {

        this.filteredIssues = [];
        for (var i = 0; i < issues.length; i++) {
            let issue = issues[i];
            let createdDate = issue.fields.created.split('T')[0];
            if (createdDate == issueDate) {
                this.filteredIssues.push(issue);
            }
        }
        return this.filteredIssues;
    }

    vulnerabilityRatingIP(issues, slice, field) {
        let uniqueIps = [];
        let uniqueIpsCount = 0;

        this.BarIPs = [];
        for (var i = 0; i < issues.length; i++) {
            if (issues[i].fields[field]) {
                if (issues[i].fields[field].toLowerCase() === slice && issues[i].fields.labels.length > 0) {
                    var ip = issues[i].fields.labels[0];
                    if (ip.indexOf('.') > -1) {
                        // this.BarIPs.push(ip);
                        this.BarIPs.push(issues[i]);

                        uniqueIps = Array.from(new Set(this.BarIPs))
                        uniqueIpsCount = uniqueIps.length;
                    }
                }
            }
        }
        return of(this.BarIPs);
    }
    filterSliceIssues(issues, slice, field) {
        let uniqueIps = [];
        let uniqueIpsCount = 0;

        this.sliceIPs = [];
        if (issues && issues.length > 0) {
            for (var i = 0; i < issues.length; i++) {
                if (issues[i].fields[field]) {
                    let fieldValue = issues[i].fields[field].toLowerCase();
                    let chartValue = slice.dataItem.dataContext.type.toLowerCase();
                    // if (issues[i].fields[field].toLowerCase() === slice.dataItem.dataContext.type && issues[i].fields.labels.length > 0) {
                    if (fieldValue === chartValue && issues[i].fields.labels.length > 0) {
                        var ip = issues[i].fields.labels[0];
                        if (ip.indexOf('.') > -1) {
                            // this.sliceIPs.push(ip);
                            this.sliceIPs.push(issues[i]);

                            uniqueIps = Array.from(new Set(this.sliceIPs))
                            uniqueIpsCount = uniqueIps.length;
                        }
                    }
                }
            }
        }
        return of(this.sliceIPs);
    }
    filterCVSSIssues(issues, bar, field) {
        let uniqueIps = [];
        let uniqueIpsCount = 0;

        this.BarIPs = [];
        if (issues && issues.length > 0) {

            for (var i = 0; i < issues.length; i++) {
                if (field === 'status') {
                    if (issues[i].fields[field]) {
                        if (issues[i].fields[field].name === bar.dataItem.dataContext.type && issues[i].fields.labels.length > 0) {
                            var ip = issues[i].fields.labels[0];
                            if (ip.indexOf('.') > -1) {
                                // this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);

                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                            }
                        }
                    }
                }
                else {
                    if (issues[i].fields[field]) {
                        if (issues[i].fields[field].value === bar.dataItem.dataContext.type && issues[i].fields.labels.length > 0) {
                            var ip = issues[i].fields.labels[0];
                            if (ip.indexOf('.') > -1) {
                                //  this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);

                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                            }
                        }
                    }
                }
            }
        }
        return of(this.BarIPs);
    }
    filterBarIssues(issues, bar, field) {
        let uniqueIps = [];
        let uniqueIpsCount = 0;

        this.BarIPs = [];
        if (issues && issues.length > 0) {
            for (var i = 0; i < issues.length; i++) {
                if (field === 'status') {
                    if (issues[i].fields[field]) {
                        let fieldValue = issues[i].fields[field].name.toLowerCase();
                        let chartValue = bar.dataItem.dataContext.type.toLowerCase();
                        // if (issues[i].fields[field].name === bar.dataItem.dataContext.type && issues[i].fields.labels.length > 0) {
                        if (fieldValue === chartValue && issues[i].fields.labels.length > 0) {
                            var ip = issues[i].fields.labels[0];
                            if (ip.indexOf('.') > -1) {
                                // this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);
                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                            }
                        }
                    }
                }
                else {
                    if (issues[i].fields[field] && bar.dataItem.dataContext.type) {
                        if (issues[i].fields[field].toLowerCase() === bar.dataItem.dataContext.type.toLowerCase() && issues[i].fields.labels.length > 0) {
                            var ip = issues[i].fields.labels[0];
                            if (ip.indexOf('.') > -1) {
                                // this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);
                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                            }
                        }
                    }
                    if (issues[i].fields[field] && bar.dataItem.dataContext.category) {
                        if (issues[i].fields[field].toLowerCase() === bar.dataItem.dataContext.category.toLowerCase() && issues[i].fields.labels.length > 0) {
                            var ip = issues[i].fields.labels[0];
                            if (ip.indexOf('.') > -1) {
                                // this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);
                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                            }
                        }
                    }
                }
            }
        }
        return of(this.BarIPs);
    }

    
    OWASP(issues) {

        let OwaspArray = [];
        let owaspdata = {}
        for (var i = 0; i < issues.length; i++) {
            if (issues[i].fields !== undefined) {
                if (issues[i].fields.customfield_10036 == null) {
                    let nullOs = "nullOWSP";

                    if (owaspdata.hasOwnProperty(nullOs))
                        owaspdata[nullOs] = owaspdata[nullOs] + 1;
                    else
                        owaspdata[nullOs] = 1;
                } else if (issues[i].fields.customfield_10036 !== null) {
                    let owsp = issues[i].fields.customfield_10036;

                    if (owaspdata.hasOwnProperty(owsp))
                        owaspdata[owsp] = owaspdata[owsp] + 1;
                    else
                        owaspdata[owsp] = 1;
                }
            }
        }
        for (let key in owaspdata) {
            OwaspArray.push({ key: key.toString(), 'count': owaspdata[key] });
        }

        let BrokenAuthentication= 0, Injection= 0, SensitiveDataExposure= 0, XMLExternalEntities= 0,
            BrokenAccesControl= 0, SystemMisconfiguration= 0, CrossSS= 0, insecureDes= 0, insufficientLogging= 0, UCWKV = 0;
        for (var i = 0; i < OwaspArray.length; i++) {
            if (OwaspArray[i].key.toLowerCase() === "injection") {
                Injection += OwaspArray[i].count;
            } else if (OwaspArray[i].key.toLowerCase() === "broken authentication") {
                BrokenAuthentication += OwaspArray[i].count;
            } else if (OwaspArray[i].key.toLowerCase() === "sensitive data exposure") {
                SensitiveDataExposure += OwaspArray[i].count;
            } else if (OwaspArray[i].key.toLowerCase() === "xml external entities (xxe)") {
                XMLExternalEntities += OwaspArray[i].count;
            } else if (OwaspArray[i].key.toLowerCase() === "broken access control") {
                BrokenAccesControl += OwaspArray[i].count;
            } else if (OwaspArray[i].key.toLowerCase() === "security misconfiguration") {
                SystemMisconfiguration += OwaspArray[i].count;
            } else if (OwaspArray[i].key.toLowerCase() === "cross-site scripting (xss)") {
                CrossSS += OwaspArray[i].count;
            } else if (OwaspArray[i].key.toLowerCase() === "insecure deserialization") {
                insecureDes += OwaspArray[i].count;
            } else if (OwaspArray[i].key.toLowerCase() === "using components with known vulnerabilities") {
                UCWKV += OwaspArray[i].count;
            } else if (OwaspArray[i].key.toLowerCase() === "insufficient logging and monitoring") {
                insufficientLogging += OwaspArray[i].count;
            }
        }

        let OWASP = {
            BA: BrokenAuthentication,
            INJ: Injection,
            SDE: SensitiveDataExposure,
            XEE: XMLExternalEntities,
            BAC: BrokenAccesControl,
            SMC: SystemMisconfiguration,
            CSS: CrossSS,
            IND: insecureDes,
            INL: insufficientLogging,
            UCWKV: UCWKV
        }
        return OWASP;
    }
    CVSS(issues) {
        let cvssArray = [];
        let cvssdata = {}
        for (var i = 0; i < issues.length; i++) {
            if (issues[i].fields !== undefined) {
                if (issues[i].fields.customfield_10044 == null) {
                    let nullOs = "nullCVSS";

                    if (cvssdata.hasOwnProperty(nullOs))
                        cvssdata[nullOs] = cvssdata[nullOs] + 1;
                    else
                        cvssdata[nullOs] = 1;
                } else if (issues[i].fields.customfield_10044.value !== null) {
                    let cvss = issues[i].fields.customfield_10044.value;

                    if (cvssdata.hasOwnProperty(cvss))
                        cvssdata[cvss] = cvssdata[cvss] + 1;
                    else
                        cvssdata[cvss] = 1;
                }
            }
        }
        for (let key in cvssdata) {
            cvssArray.push({ key: key.toString(), 'count': cvssdata[key] });
        }
        let one, two, three, four, five, six, seven, eight, nine, ten = 0;

        for (var i = 0; i < cvssArray.length; i++) {
            if (cvssArray[i].key == "1") {
                one = cvssArray[i].count;
            } else if (cvssArray[i].key == "2") {
                two = cvssArray[i].count;
            } else if (cvssArray[i].key == "3") {
                three = cvssArray[i].count;
            } else if (cvssArray[i].key == "4") {
                four = cvssArray[i].count;
            } else if (cvssArray[i].key == "5") {
                five = cvssArray[i].count;
            } else if (cvssArray[i].key == "6") {
                six = cvssArray[i].count;
            } else if (cvssArray[i].key == "7") {
                seven = cvssArray[i].count;
            } else if (cvssArray[i].key == "8") {
                eight = cvssArray[i].count;
            } else if (cvssArray[i].key == "9") {
                nine = cvssArray[i].count;
            } else if (cvssArray[i].key == "10") {
                ten = cvssArray[i].count;
            }
        }
        let CVSS = {
            One: one,
            Two: two,
            Three: three,
            Four: four,
            Five: five,
            Six: six,
            Seven: seven,
            Eight: eight,
            Nine: nine,
            Ten: ten
        }
        return CVSS;
    }
    IssueStatus(issues, flag=false) {
        let issueStatusObject = {};
        let IssueStatusArray = [];
        for (let i = 0; i < issues.length; i++) {
            if (issues[i].fields !== undefined) {
                if (issues[i].fields.assignee == null) {
                    let nullOs = "NullAssignee";

                    if (issueStatusObject.hasOwnProperty(nullOs))
                        issueStatusObject[nullOs] = issueStatusObject[nullOs] + 1;
                    else
                        issueStatusObject[nullOs] = 1;
                } else if (issues[i].fields.assignee !== null) {
                    let status = "haveAssignee";

                    if (issueStatusObject.hasOwnProperty(status))
                        issueStatusObject[status] = issueStatusObject[status] + 1;
                    else
                        issueStatusObject[status] = 1;
                }

                if (issues[i].fields.status.name == null) {
                    let nullstatus = "NullStatus";

                    if (issueStatusObject.hasOwnProperty(nullstatus))
                        issueStatusObject[nullstatus] = issueStatusObject[nullstatus] + 1;
                    else
                        issueStatusObject[nullstatus] = 1;
                } else if (issues[i].fields.status.name !== null) {
                    let issuestatus = issues[i].fields.status.name;

                    if (issueStatusObject.hasOwnProperty(issuestatus))
                        issueStatusObject[issuestatus] = issueStatusObject[issuestatus] + 1;
                    else
                        issueStatusObject[issuestatus] = 1;
                }
            }

        }
        for (let key in issueStatusObject) {
            IssueStatusArray.push({ key: key.toString(), 'count': issueStatusObject[key] });
        }

        let open = 0, closed = 0, unassigned = 0, assigned = 0, inprogress = 0;

        for (var i = 0; i < IssueStatusArray.length; i++) {
            if (IssueStatusArray[i].key == "To Do") {
                open = IssueStatusArray[i].count;
            } else if (IssueStatusArray[i].key == "Done") {
                closed = IssueStatusArray[i].count;
            } else if (IssueStatusArray[i].key == "NullAssignee") {
                unassigned = IssueStatusArray[i].count;
            } else if (IssueStatusArray[i].key == "In Progress") {
                inprogress = IssueStatusArray[i].count;

            } else if (IssueStatusArray[i].key == "haveAssignee") {
                assigned = IssueStatusArray[i].count;
            }
        }

        let issueStatus = {
            Open: open,
            Closed: closed,
            Unassigned: unassigned,
            Assigned: assigned,
            InProgress: inprogress
        }
        return issueStatus;
    }

    Timeline(issues) {
        let timelineData = [];
        let tempData = {};

        for (var i = 0; i < issues.length; i++) {
            let currentIssue = issues[i];
            let createdDate = this.getDateOnlyFromIsoDate(this.dateTimeZone(currentIssue.fields.created));
            let resolutionDate = currentIssue.fields.resolutiondate;
            if (!tempData[createdDate])
                tempData[createdDate] = {
                    createdCount: 1,
                    resolvedCount: 0,
                    toDoCount: 0,
                    inProgressCount: 0,
                    total: 0
                }
            else {
                tempData[createdDate].createdCount += 1;
            }

            if (currentIssue.fields.status.name === "To DO") {
                tempData[createdDate].toDOCount += 1;
            }
            if (currentIssue.fields.status.name === "In Progress") {
                tempData[createdDate].inProgressCount += 1;
            }

            // if (resolutionDate) {
            //     tempData[createdDate].resolvedCount += 1;
            // }
        //funQari
            if (resolutionDate) {
				let resolvedDate = this.getDateOnlyFromIsoDate(this.dateTimeZone(resolutionDate));
				  if (!tempData[resolvedDate])
						tempData[resolvedDate] = {
							createdCount: 0,
							resolvedCount: 1,
							toDoCount: 0,
							inProgressCount: 0,
							total: 0
						}
					else {
						tempData[resolvedDate].resolvedCount += 1;
					}
            }
        }
            let ordered = {};
            Object.keys(tempData).sort().forEach(function(key) {
            ordered[key] = tempData[key];
            });
        tempData = ordered
        //funQari End
        let oldCreatedCount=0
        let oldResolvedCount=0
        for (let key in tempData) {

            oldCreatedCount = tempData[key]["createdCount"] + oldCreatedCount;
            tempData[key]["createdCount"] = oldCreatedCount;

            oldResolvedCount = tempData[key]["resolvedCount"] + oldResolvedCount;
            tempData[key]["resolvedCount"] = oldResolvedCount;
            
            timelineData.push({ 'date': key.toString(), ...tempData[key] });
        }

        return timelineData;
    }

    AverageRisk(issues) {

        let cia = { 'confidentiality': 1, 'integrity': 2, 'financial': 3 }
        let company = { 'Banks': 1, 'NGO': 2, 'SmallBusinesses': 3 };
        //app 1.5 types
        let oldType = {
            'loss_of_revenue': 3, 'increased_operating_expenses': 2, 'penalties': 2, 'fines': 2, 'internal_operations': 3,
            'impact_to_liquidity': 3, 'impact_on_reputation': 3, 'sanctions': 3, 'impact_to_customer_service': 2,
            'legal': 3, 'regulatory': 3, 'customer_relationship': 2, 'customer_information_theift': 3,
            'impact_to_patient_safety': 3, 'impact_to_brand': 2, 'delay_in_services': 2, 'impact_on_customer_service': 2,
            'environmental_impact': 2,
        };
        //new Types app 1.7
        let Type = {
            'customer_relationship_impact': 3,
            'financial_impact': 3,
            'internal_operations_impact': 2,
            'legal/regulatory_impact': 3,
            'environmental_impact': 2,
            'human_health_impact': 3,
            'company_image_impact': 3
        }
        let vulnerability_rating = {
            'low': 1, 'medium': 2, 'critical': 3
        };
        let riskTable = [];
        let assetsInFilter = 0;
        for (var i = 0; i < issues.length; i++) {
            if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10053 && issues[i].fields.customfield_10044 && issues[i].fields.customfield_10052) {

                let ciaKey = issues[i].fields.customfield_10056;
                let impactTypeKey = issues[i].fields.customfield_10053;
                let cvss = issues[i].fields.customfield_10044.value;
                let v_rating = issues[i].fields.customfield_10052;
                impactTypeKey = impactTypeKey.replace(/ /g, "_").toLowerCase();
                var impactValue = 0, ciaValue = 0, v_ratingValue = 0;
                Object.keys(Type).forEach(key => {
                    if (key === impactTypeKey) {
                        impactValue = Type[key];
                        assetsInFilter++;
                    }
                });
                // Object.keys(cia).forEach(key => {
                //     if (key == ciaKey) {
                //         ciaValue = cia[key];
                //     }
                // });
                ciaValue = 1;
                Object.keys(vulnerability_rating).forEach(key => {

                    if (key === v_rating.toLowerCase()) {
                        v_ratingValue = vulnerability_rating[key];
                    }
                });

                let asset = (ciaValue * impactValue);
                let threat = cvss;
                let vulnerability = v_ratingValue;
                let insecurity = (asset * threat * vulnerability);
                riskTable.push({
                    insecurity: insecurity
                });

                // let BusinessImpactRating = (asset * company.Banks * threat); // 1 is default value used for Banks, Telecom and Insurance Companies
                // let RiskAssesmetRating = (asset * threat * vulnerability);
                // riskTable.push({
                //     businessImpactRating: BusinessImpactRating,
                //     riskAssesmetRating: RiskAssesmetRating
                // });
            }
        }
        Array.prototype.sum = function (prop) {
            var total = 0;
            for (var i = 0, _len = this.length; i < _len; i++) {
                total += this[i][prop];
            }
            return total;
        };
        let avgRisk = (riskTable.sum('insecurity') / riskTable.length);

        // // let avgBusinessImpactRating = (riskTable.sum("businessImpactRating") / riskTable.length);
        // // let avgRiskAssesmetRating = (riskTable.sum("riskAssesmetRating") / riskTable.length);
        // let avgRisk = ((avgBusinessImpactRating + avgRiskAssesmetRating) / assetsInFilter);
        return avgRisk;
        // let a = cia* impactType;

        // let t = cvss;
        // let v = vulnerability_rating

        // let businessImpactRating = Asset*1*CVSS
        // let RiskAssesmetRating = Asset*1*CVSS
        //let AVGRisk = 

    }
    Top20BusinessImpact(issues) {

        let cia = { 'confidentiality': 1, 'integrity': 2, 'financial': 3 };
        let company = { 'Banks': 1, 'NGO': 2, 'SmallBusinesses': 3 };
        let oldType = {
            'loss_of_revenue': 3, 'increased_operating_expenses': 2, 'penalties': 2, 'fines': 2, 'internal_operations': 3,
            'impact_to_liquidity': 3, 'impact_on_reputation': 3, 'sanctions': 3, 'impact_to_customer_service': 2,
            'legal': 3, 'regulatory': 3, 'customer_relationship': 2, 'customer_information_theift': 3,
            'impact_to_patient_safety': 3, 'impact_to_brand': 2, 'delay_in_services': 2, 'impact_on_customer_service': 2,
            'environmental_impact': 2
        };
        let Type = {
            'customer_relationship_impact': 3,
            'Financial Impact': 3,
            'internal_operations_impact': 2,
            'legal/regulatory_impact': 3,
            'environmental_impact': 2,
            'human_health_impact': 3,
            'company_image_impact': 3
        }
        let vulnerability_rating = {
            'low': 1, 'medium': 2, 'critical': 3
        };
        let riskTable = [];
        let assetsInFilter = 0;
        for (var i = 0; i < issues.length; i++) {
            if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10053 && issues[i].fields.customfield_10044 && issues[i].fields.customfield_10052) {

                let ciaKey = issues[i].fields.customfield_10056;
                let impactTypeKey = issues[i].fields.customfield_10053;
                let cvss = issues[i].fields.customfield_10044.value;
                let v_rating = issues[i].fields.customfield_10052;
                impactTypeKey = impactTypeKey.replace(/ /g, "_").toLowerCase();
                var impactValue = 0, ciaValue = 0, v_ratingValue = 0;
                Object.keys(Type).forEach(key => {
                    if (key == impactTypeKey) {
                        impactValue = Type[key];
                        assetsInFilter++;
                    }
                });
                ciaValue = 1;
                Object.keys(vulnerability_rating).forEach(key => {

                    if (key === v_rating.toLowerCase()) {
                        v_ratingValue = vulnerability_rating[key];
                    }
                });

                let asset = (ciaValue * impactValue);
                let threat = cvss;
                let vulnerability = v_ratingValue;
                let BusinessImpactRating = (asset * company.Banks * threat); // 1 is default value used for Banks, Telecom and Insurance Companies
                riskTable.push({
                    businessImpactRating: BusinessImpactRating,
                });

            }
        }
        const WordsWithFreq = {};

        riskTable.forEach((risk, i) => {
            if (risk) {
                let wordFreq = WordsWithFreq[risk.businessImpactRating];
                if (wordFreq) {
                    wordFreq.count = ++wordFreq.count;
                } else {
                    WordsWithFreq[risk.businessImpactRating] = {
                        impact: risk.businessImpactRating,
                        count: 1,
                    };
                }
            };
        });

        // iterating objects on the basis of key to convert into array.
        const resultArray = [];
        Object.keys(WordsWithFreq).forEach(key => {
            resultArray.push({ impact: WordsWithFreq[key].impact, count: WordsWithFreq[key].count });
        });
        const sortedFreq = resultArray.sort((a: any, b: any) => {
            if (a.impact > b.impact) {
                return -1;
            }
            if (a.impact < b.impact) {
                return 1;
            }
        });

        return sortedFreq;

    }
    Top20RiskRate(issues) {

        let cia = { 'confidentiality': 1, 'integrity': 2, 'financial': 3 }
        let oldType = {
            'loss_of_revenue': 3, 'increased_operating_expenses': 2, 'penalties': 2, 'fines': 2, 'internal_operations': 3,
            'impact_to_liquidity': 3, 'impact_on_reputation': 3, 'sanctions': 3, 'impact_to_customer_service': 2,
            'legal': 3, 'regulatory': 3, 'customer_relationship': 2, 'customer_information_theift': 3,
            'impact_to_patient_safety': 3, 'impact_to_brand': 2, 'delay_in_services': 2, 'impact_on_customer_service': 2,
            'environmental_impact': 2
        };
        let Type = {
            'customer_relationship_impact': 3,
            'Financial Impact': 3,
            'internal_operations_impact': 2,
            'legal/regulatory_impact': 3,
            'environmental_impact': 2,
            'human_health_impact': 3,
            'company_image_impact': 3
        }
        let vulnerability_rating = {
            'low': 1, 'medium': 2, 'critical': 3
        };
        let riskTable = [];
        let assetsInFilter = 0;
        for (var i = 0; i < issues.length; i++) {
            if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10053 && issues[i].fields.customfield_10044 && issues[i].fields.customfield_10052) {

                let ciaKey = issues[i].fields.customfield_10056;
                let impactTypeKey = issues[i].fields.customfield_10053;
                let cvss = issues[i].fields.customfield_10044.value;
                let v_rating = issues[i].fields.customfield_10052;
                impactTypeKey = impactTypeKey.replace(/ /g, "_").toLowerCase();
                var impactValue = 0, ciaValue = 0, v_ratingValue = 0;
                Object.keys(Type).forEach(key => {
                    if (key == impactTypeKey) {
                        impactValue = Type[key];
                        assetsInFilter++;
                    }
                });
                ciaValue = 1;
                Object.keys(vulnerability_rating).forEach(key => {

                    if (key === v_rating.toLowerCase()) {
                        v_ratingValue = vulnerability_rating[key];
                    }
                });

                let asset = (ciaValue * impactValue);
                let threat = cvss;
                let vulnerability = v_ratingValue;
                let RiskAssesmetRating = (asset * threat * vulnerability);
                riskTable.push({
                    riskAssesmetRating: RiskAssesmetRating
                });
            }
        }

        const WordsWithFreq = {};

        riskTable.forEach((risk, i) => {
            if (risk) {
                let wordFreq = WordsWithFreq[risk.riskAssesmetRating];
                if (wordFreq) {
                    wordFreq.count = ++wordFreq.count;
                } else {
                    WordsWithFreq[risk.riskAssesmetRating] = {
                        risk: risk.riskAssesmetRating,
                        count: 1,
                    };
                }
            };
        });
        // iterating objects on the basis of key to convert into array.
        const resultArray = [];
        Object.keys(WordsWithFreq).forEach(key => {
            resultArray.push({ risk: WordsWithFreq[key].risk, count: WordsWithFreq[key].count });
        });
        const sortedFreq = resultArray.sort((a: any, b: any) => {
            if (a.risk > b.risk) {
                return -1;
            }
            if (a.risk < b.risk) {
                return 1;
            }
        });
        return sortedFreq;

    }
    Top20HighBusinessImpact(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10042) {

                    let BusinessImpact = ""+issues[i].fields.customfield_10042.value;
                    let wordFreq = WordsWithFreq[BusinessImpact.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[BusinessImpact.toLowerCase()] = {
                            count: 1,
                            word: BusinessImpact
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;
    }
    Top20HighRiskRate(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10043) {

                    let riskRate = ""+issues[i].fields.customfield_10043.value;
                    let wordFreq = WordsWithFreq[riskRate.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[riskRate.toLowerCase()] = {
                            count: 1,
                            word: riskRate
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;
    }
    SortedOS(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10050) {

                    let os = issues[i].fields.customfield_10050;
                    let wordFreq = WordsWithFreq[os.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[os.toLowerCase()] = {
                            count: 1,
                            word: os
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;
    }
    VulnerabilitiesBySeverity(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.customfield_10052) {

                    let severity = issues[i].fields.customfield_10052;
                    let wordFreq = WordsWithFreq[severity.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[severity.toLowerCase()] = {
                            count: 1,
                            word: severity
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreqWithCount = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            const sortedFreq = sortedFreqWithCount.sort((a: any, b: any) => {
                return a.word < b.word ? -1 : 1; //sorting word alphabetically. 
            });
            return sortedFreq;
        }
        return null;
    }
    VulnerabilitiesBySeverityExceptDone(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.customfield_10052 && issues[i].fields.status.name.toLowerCase() !== "done") {

                    let severity = issues[i].fields.customfield_10052;
                    let wordFreq = WordsWithFreq[severity.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[severity.toLowerCase()] = {
                            count: 1,
                            word: severity
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreqWithCount = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            const sortedFreq = sortedFreqWithCount.sort((a: any, b: any) => {
                return a.word < b.word ? -1 : 1; //sorting word alphabetically. 
            });
            return sortedFreq;
        }
        return null;
    }

    IssuesByStatus(issues) {

        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name) {

                    let status = issues[i].fields.status.name;
                    let wordFreq = WordsWithFreq[status];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[status] = {
                            count: 1,
                            word: status
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;

    }
    SortedImpactType(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10053) {

                    let impact = issues[i].fields.customfield_10053;
                    let wordFreq = WordsWithFreq[impact.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[impact.toLowerCase()] = {
                            count: 1,
                            word: impact
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;
    }
    SortedTestType(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10049) {

                    let impact = issues[i].fields.customfield_10049;
                    let wordFreq = WordsWithFreq[impact.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[impact.toLowerCase()] = {
                            count: 1,
                            word: impact
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;
    }

    Top10Category(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};
            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10059) {

                    let technology = issues[i].fields.customfield_10059;
                    let wordFreq = WordsWithFreq[technology.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[technology.toLowerCase()] = {
                            count: 1,
                            word: technology
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;
    }
    Vector(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10054) {

                    let impact = issues[i].fields.customfield_10054;
                    let wordFreq = WordsWithFreq[impact.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[impact.toLowerCase()] = {
                            count: 1,
                            word: impact
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;
    }
    Actor(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10055) {

                    let impact = issues[i].fields.customfield_10055;
                    let wordFreq = WordsWithFreq[impact.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[impact.toLowerCase()] = {
                            count: 1,
                            word: impact
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;
    }
    CIADamage(issues) {
        if (issues instanceof Array) { // Check if input is array.
            const WordsWithFreq = {};

            issues.forEach((issue, i) => {
                if (issues[i].fields.status.name.toLowerCase() !== 'done' && issues[i].fields.customfield_10056) {

                    let impact = issues[i].fields.customfield_10056;
                    let wordFreq = WordsWithFreq[impact.toLowerCase()];
                    if (wordFreq) {
                        wordFreq.count = ++wordFreq.count;
                    } else {
                        WordsWithFreq[impact.toLowerCase()] = {
                            count: 1,
                            word: impact
                        };
                    }
                };
            });
            // iterating objects on the basis of key to convert into array.
            const resultArray = [];
            Object.keys(WordsWithFreq).forEach(key => {
                resultArray.push({ word: key, count: WordsWithFreq[key].count });
            });
            // sorting in descending order by count
            const sortedFreq = resultArray.sort((a: any, b: any) => {
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
            });
            return sortedFreq;
        }
        return null;
    }

    doneFilter(issues) : Observable<any> {
        if (issues instanceof Array) { // Check if input is array.
            let response = null;
            let costumResopnce=[];
            let doneIssues=[];
            response = issues;
            for (let i = 0; i < response.length; i++) {
                if(response[i].fields.status.name.toLowerCase() != "done"){
                    costumResopnce.push(response[i]);
                }
                else{
                    doneIssues.push(response[i]);
                }                
            }
            // console.log(JSON.stringify(doneIssues));
            return of(costumResopnce);
        }
        return null;
    }

    filterIssueOnDate(toDate,issues){
        let dateBaseFilteredIssues=[];
        let dateBaseFilteredIssues2 = [];
        for (let i = 0; i < issues.length; i++) {
            let resolved =issues[i].fields.resolutiondate ? issues[i].fields.resolutiondate.split('T'):null;
            let created = issues[i].fields.created.split('T');
            let date = null;
			// let resolvedDate = new Date(this.dateTimeZone(resolved[0])); 
            
            date = new Date(this.dateTimeZone(created[0]));           
			
            // let toCreatedDate = new Date(this.dateTimeZone(toDate));
            let toCreatedDate = new Date(this.dateTimeZone(this.oneDayMinus(toDate)));
            
            if (date <= toCreatedDate || (resolved && new Date(this.dateTimeZone(resolved[0])) <= toCreatedDate)) {
                dateBaseFilteredIssues.push(issues[i]);
            }
			// else if(resolved){
			// 	let resolvedDate = new Date(this.dateTimeZone(resolved[0])); 
			// 	 if (resolvedDate <= toCreatedDate) {
			// 		dateBaseFilteredIssues.push(issues[i]);
			// 	}
            // }

            if(resolved)   date = new Date(this.dateTimeZone(resolved[0]));
            if (date <= toCreatedDate) {
                dateBaseFilteredIssues2.push(issues[i]);
            }
        }
        return {issue1:dateBaseFilteredIssues,issue2:dateBaseFilteredIssues2};
    }

    informationalSpliter(issues, type): Observable<any> {
        if (issues instanceof Array) { // Check if input is array.
            let response = null;
            let normalIssues = [];
            let informationalIssues = [];
            let filteredIssues = [];
            response = issues;
            if (type === 0) {
                return of(response);
            }
            for (let i = 0; i < response.length; i++) {
                if (type === 1 && response[i].fields.customfield_10052 && response[i].fields.customfield_10052.toLowerCase() != "informational") {
                    filteredIssues.push(response[i]);
                }
                else if (type === 2 && response[i].fields.customfield_10052 && response[i].fields.customfield_10052.toLowerCase() === "informational") {
                    filteredIssues.push(response[i]);
                }

            }
            return of(filteredIssues);
        }
        return null;
    }

   
    dateTimeZone(date){
        if(date){
            return new Date(date).toLocaleString("en-US", {timeZone: "Asia/Dubai"});
        }
        else
            return new Date().toLocaleString("en-US", {timeZone: "Asia/Dubai"});
    }

    oneDayMinus(date){
        let date1 = +new Date(date);
        let cre = date1 - +new Date(24*60*60*1000);
        return new Date(cre).toISOString().split('T')[0];
    }

    datewiseTimeline(issues,tillDate) {
        let timelineData = [];
        let tempData = {};

        for (var i = 0; i < issues.length; i++) {
            let currentIssue = issues[i];
            let createdDate = this.getDateOnlyFromIsoDate(this.dateTimeZone(currentIssue.fields.created));
            let resolutionDate = currentIssue.fields.resolutiondate;
            if (!tempData[createdDate])
                tempData[createdDate] = {
                    createdCount: 1,
                    resolvedCount: 0,
                    toDoCount: 0,
                    inProgressCount: 0,
                    total: 0
                }
            else {
                tempData[createdDate].createdCount += 1;
            }

            if (currentIssue.fields.status.name === "To DO") {
                tempData[createdDate].toDOCount += 1;
            }
            if (currentIssue.fields.status.name === "In Progress") {
                tempData[createdDate].inProgressCount += 1;
            }

            // if (resolutionDate) {
            //     tempData[createdDate].resolvedCount += 1;
            // }
        //funQari
            if (resolutionDate) {
                let rdate = this.getDateOnlyFromIsoDate(this.dateTimeZone(resolutionDate));
                let compareToDate = this.getDateOnlyFromIsoDate(this.dateTimeZone(this.oneDayMinus(tillDate)));
                if (new Date(rdate) <= new Date(compareToDate)) {
                    let resolvedDate = this.getDateOnlyFromIsoDate(this.dateTimeZone(resolutionDate));
                    if (!tempData[resolvedDate])
                        tempData[resolvedDate] = {
                            createdCount: 0,
                            resolvedCount: 1,
                            toDoCount: 0,
                            inProgressCount: 0,
                            total: 0
                        }
                    else {
                        tempData[resolvedDate].resolvedCount += 1;
                    }
                }
            }
        }
            let ordered = {};
            Object.keys(tempData).sort().forEach(function(key) {
            ordered[key] = tempData[key];
            });
        tempData = ordered
        //funQari End
        let oldCreatedCount=0
        let oldResolvedCount=0
        for (let key in tempData) {

            oldCreatedCount = tempData[key]["createdCount"] + oldCreatedCount;
            tempData[key]["createdCount"] = oldCreatedCount;

            oldResolvedCount = tempData[key]["resolvedCount"] + oldResolvedCount;
            tempData[key]["resolvedCount"] = oldResolvedCount;
            
            timelineData.push({ 'date': key.toString(), ...tempData[key] });
        }

        return timelineData;
    }

    dateWiseIssueStatus(issues,todate, flag=false) {
        let issueStatusObject = {};
        let IssueStatusArray = [];
        for (let i = 0; i < issues.length; i++) {
            if (issues[i].fields !== undefined) {
                if (issues[i].fields.assignee == null) {
                    let nullOs = "NullAssignee";

                    if (issueStatusObject.hasOwnProperty(nullOs))
                        issueStatusObject[nullOs] = issueStatusObject[nullOs] + 1;
                    else
                        issueStatusObject[nullOs] = 1;
                } else if (issues[i].fields.assignee !== null) {
                    let status = "haveAssignee";

                    if (issueStatusObject.hasOwnProperty(status))
                        issueStatusObject[status] = issueStatusObject[status] + 1;
                    else
                        issueStatusObject[status] = 1;
                }

                if (issues[i].fields.status.name == null) {
                    let nullstatus = "NullStatus";

                    if (issueStatusObject.hasOwnProperty(nullstatus))
                        issueStatusObject[nullstatus] = issueStatusObject[nullstatus] + 1;
                    else
                        issueStatusObject[nullstatus] = 1;
                } else if (issues[i].fields.status.name !== null) {
                    let issuestatus = issues[i].fields.status.name;

                    if (issueStatusObject.hasOwnProperty(issuestatus))
                        issueStatusObject[issuestatus] = issueStatusObject[issuestatus] + 1;
                    else
                        issueStatusObject[issuestatus] = 1;
                }
            }

        }
        for (let key in issueStatusObject) {
            IssueStatusArray.push({ key: key.toString(), 'count': issueStatusObject[key] });
        }

        let open = 0, closed = 0, unassigned = 0, assigned = 0, inprogress = 0;

        for (var i = 0; i < IssueStatusArray.length; i++) {
            if (IssueStatusArray[i].key == "To Do") {
                open = IssueStatusArray[i].count;
            } else if (IssueStatusArray[i].key == "Done") {
                closed = IssueStatusArray[i].count;
            } else if (IssueStatusArray[i].key == "NullAssignee") {
                unassigned = IssueStatusArray[i].count;
            } else if (IssueStatusArray[i].key == "In Progress") {
                inprogress = IssueStatusArray[i].count;

            } else if (IssueStatusArray[i].key == "haveAssignee") {
                assigned = IssueStatusArray[i].count;
            }
        }

        let issueStatus = {
            Open: open,
            Closed: closed,
            Unassigned: unassigned,
            Assigned: assigned,
            InProgress: inprogress
        }
        return issueStatus;
    }

    //new for issuefieldDB
    _vulnerabilityRatingIP(issues, slice, field) {
        let uniqueIps = [];
        let uniqueIpsCount = 0;

        this.BarIPs = [];
        for (var i = 0; i < issues.length; i++) {
            if (issues[i][field]) {
                if (issues[i][field].toLowerCase() === slice && issues[i].labels.length > 0) {
                    var ip = issues[i].labels[0];
                    if (ip.indexOf('.') > -1) {
                        // this.BarIPs.push(ip);
                        this.BarIPs.push(issues[i]);

                        uniqueIps = Array.from(new Set(this.BarIPs))
                        uniqueIpsCount = uniqueIps.length;
                    }
                }
            }
        }
        return of(this.BarIPs);
    }
    _filterSliceIssues(issues, slice, field) {
        let uniqueIps = [];
        let uniqueIpsCount = 0;

        this.sliceIPs = [];
        if (issues && issues.length > 0) {
            for (var i = 0; i < issues.length; i++) {
                if (issues[i][field]) {
                    let fieldValue = issues[i][field].toLowerCase();
                    let chartValue = slice.dataItem.dataContext.type.toLowerCase();
                    // if (issues[i].fields[field].toLowerCase() === slice.dataItem.dataContext.type && issues[i].fields.labels.length > 0) {
                    if (fieldValue === chartValue && issues[i].labels.length > 0) {
                        var ip = issues[i].labels[0];
                        if (ip.indexOf('.') > -1) {
                            // this.sliceIPs.push(ip);
                            this.sliceIPs.push(issues[i]);

                            uniqueIps = Array.from(new Set(this.sliceIPs))
                            uniqueIpsCount = uniqueIps.length;
                        }
                    }
                }
            }
        }
        return of(this.sliceIPs);
    }
    _filterCVSSIssues(issues, bar, field) {
        let uniqueIps = [];
        let uniqueIpsCount = 0;

        this.BarIPs = [];
        if (issues && issues.length > 0) {

            for (var i = 0; i < issues.length; i++) {
                if (field === 'status') {
                    if (issues[i][field]) {
                        if (issues[i][field] === bar.dataItem.dataContext.type && issues[i].labels.length > 0) {
                            var ip = issues[i].labels[0];
                            if (ip.indexOf('.') > -1) {
                                // this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);

                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                            }
                        }
                    }
                }
                else {
                    if (issues[i][field]) {
                        if (issues[i][field] === bar.dataItem.dataContext.type && issues[i].labels.length > 0) {
                            var ip = issues[i].labels[0];
                            if (ip.indexOf('.') > -1) {
                                //  this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);

                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                            }
                        }
                    }
                }
            }
        }
        return of(this.BarIPs);
    }
	_filterImpactTypeBarIssues(issues,event, field,assets){
		console.log("---------issues-----");
		console.log(issues);
		console.log("---------field-----");
		console.log(field);
		console.log("---------assets-----");
		console.log(assets);
		console.log("---------end-----");
		
	
        let chartValue = event.dataItem.dataContext.type.toLowerCase();
		
		this.BarIPs = [];
		let uniqueIps = [];
        let uniqueIpsCount = 0;
		if (issues && issues.length > 0 ) {
			for (var i = 0; i < issues.length; i++) {
								
					if(issues[i].status.toLowerCase()!='closed' && issues[i].status.toLowerCase()!='risk accepted' && chartValue=='financial impact' && assets.financialImpact.length>0){
					
						for (var k = 0; k < assets.financialImpact.length; k++) {
							if(assets.financialImpact[k]==issues[i].ip){
								this.BarIPs.push(issues[i]);
								uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
							}
						}
					}
					
					if(issues[i].status.toLowerCase()!='closed' && issues[i].status.toLowerCase()!='risk accepted' && chartValue=='non-compliance' && assets.nonCompliance.length>0){
					
						for (var k = 0; k < assets.nonCompliance.length; k++) {
						
							if(assets.nonCompliance[k]==issues[i].ip){
								this.BarIPs.push(issues[i]);
								uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
							}
						}
					}
					if(issues[i].status.toLowerCase()!='closed' && issues[i].status.toLowerCase()!='risk accepted' && chartValue=='privacy violation impact' && assets.privacyViolationImpact.length>0){
						for (var k = 0; k < assets.privacyViolationImpact.length; k++) {
							if(assets.privacyViolationImpact[k]==issues[i].ip){
								this.BarIPs.push(issues[i]);
								uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
							}
						}
					}
					if(issues[i].status.toLowerCase()!='closed' && issues[i].status.toLowerCase()!='risk accepted' && chartValue=='reputation impact' && assets.reputationalImpact.length>0){
						for (var k = 0; k < assets.reputationalImpact.length; k++) {
							if(assets.reputationalImpact[k]==issues[i].ip){
								this.BarIPs.push(issues[i]);
								uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
							}
						}
					}
					if(issues[i].status.toLowerCase()!='closed' && issues[i].status.toLowerCase()!='risk accepted' && chartValue=='internal operations impact' && assets.InternalOperationsImpact.length>0){
						for (var k = 0; k < assets.InternalOperationsImpact.length; k++) {
							if(assets.InternalOperationsImpact[k]==issues[i].ip){
								this.BarIPs.push(issues[i]);
								uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
							}
						}
					}
					
				}
			
		}
		
		return of(this.BarIPs);
	}
    _filterBarIssues(issues, bar, field) {
        let uniqueIps = [];
        let uniqueIpsCount = 0;
        let chartsValue = {fixed:'closed', reported:'to do', 'in progress': 'in progress', remediated:'remediated', 'not fixed': 'not fixed', 'risk accepted':'risk accepted'}
        this.BarIPs = [];
        if (issues && issues.length > 0) {
            for (var i = 0; i < issues.length; i++) {
                if (field === 'status') {
                    if (issues[i][field]) {
                        let fieldValue = issues[i][field].toLowerCase();
                        let chartValue = bar.dataItem.dataContext.type.toLowerCase();
                        // if (issues[i].fields[field].name === bar.dataItem.dataContext.type && issues[i].fields.labels.length > 0) {
                        if (fieldValue === chartsValue[chartValue] && issues[i].ip.length > 0) {
                            var ip = issues[i].ip;
                           // if (ip.indexOf('.') > -1) {
                                // this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);
                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                           // }
                        }
                    }
                }
                else {
                    if (issues[i][field] && bar.dataItem.dataContext.type) {
						console.log("----------Type---------")
                        if (issues[i][field].toLowerCase() === bar.dataItem.dataContext.type.toLowerCase() && issues[i].ip.length > 0 && issues[i].status.toLowerCase() !=='done'  && issues[i].status.toLowerCase() !=='risk accepted') {
                            var ip = issues[i].ip;
                           // if (ip.indexOf('.') > -1) {
                                // this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);
                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                           // }
                        }
                    }
                    if (issues[i][field] && bar.dataItem.dataContext.category) {
					//console.log("----------Category---------")
                        if (issues[i][field].toLowerCase() === bar.dataItem.dataContext.category.toLowerCase() && issues[i].ip.length > 0 && issues[i].status.toLowerCase() !=='done'  && issues[i].status.toLowerCase() !=='risk accepted') {
                            var ip = issues[i].ip;
                            //if (ip.indexOf('.') > -1) {
                                // this.BarIPs.push(ip);
                                this.BarIPs.push(issues[i]);
                                uniqueIps = Array.from(new Set(this.BarIPs))
                                uniqueIpsCount = uniqueIps.length;
                            //}
                        }
                    }
                }
            }
        }
        return of(this.BarIPs);
    }

    reformateCountryData(country,data){
        let c = {}
        let cc = []
        if(data instanceof Array&& data.length>0){
            data.forEach(e => {
                if(c.hasOwnProperty(e)){
                    c[e]+=1
                }else{
                    c[e] = 1
                }
            });            
        }
        if(country instanceof Array&& country.length>0){
            Object.keys(c).forEach(e => {  
                let ccc = country.find(x=>x.alpha2==e.toLowerCase())
                if(ccc) cc.push({type:ccc.name, country:ccc.alpha2.toUpperCase(), count:c[e], latitude:ccc.lat, longitude:ccc.lan})
            });
        }

        const sortedFreq = cc.sort((a: any, b: any) => {
            if (a.count > b.count) {
                return -1;
            }
            if (a.count < b.count) {
                return 1;
            }
        });
        return sortedFreq.slice(0, 5)

    }

    extractCountryAlarms(country,alarms){
       
        let countryAlarms = []
        if(alarms.length>0){
            alarms.forEach(x => {
                if(x.events&&x.events[0]&&x.events[0].message&&x.events[0].message.source_country) {
                    if(country==x.events[0].message.source_country)
                    countryAlarms.push(x);
                }
            });

            return countryAlarms;
        }else {
            return []
        }
    }
	
	IssuesByIpArrayWithAssetStatus(issues,IPs,AssetStatus){
		
		//console.log("-------------Issues for IP--------");
		//console.log(issues);
		const WordsWithFreq = {};
		const IPsList =[];
		for (let j = 0; j < IPs.length; j++) {
			if(IPsList.indexOf(IPs[j])===-1){
				IPsList.push(IPs[j]);
			}
		}
		if(AssetStatus.toLowerCase()=='secured'){
				for (let j = 0; j < IPsList.length; j++) {
					var _ip = IPsList[j];
					WordsWithFreq[_ip] = {
						count: 0,
						Issue: []
					};
				}
			}else{
				for (let i = 0; i < issues.length; i++) {
			
					if(issues[i].status!='done' && issues[i].status!='Risk Accepted'  && issues[i].customfield_10043){
						for (let j = 0; j < IPsList.length; j++) {
						
							if(issues[i].ip==IPsList[j]){
								var _ip = IPsList[j];
								let risk = issues[i].customfield_10043;
								//if(AssetStatus.toLowerCase()=='high'){
									let wordFreq = WordsWithFreq[_ip];
									if (wordFreq) {
										let t = [];
										t = wordFreq.Issue;
										t.push(issues[i]);
										wordFreq.count = ++wordFreq.count;
										wordFreq.Issue = t;

									} else {
										WordsWithFreq[_ip] = {
											count: 1,
											Issue: [issues[i]]
										};
									}
								/*}else if(AssetStatus.toLowerCase()=='medium'){
									let wordFreq = WordsWithFreq[_ip];
									if (wordFreq) {
										let t = [];
										t = wordFreq.Issue;
										t.push(issues[i]);
										wordFreq.count = ++wordFreq.count;
										wordFreq.Issue = t;

									} else {
										WordsWithFreq[_ip] = {
											count: 1,
											Issue: [issues[i]]
										};
									}
								}else if(AssetStatus.toLowerCase()=='low'){
									let wordFreq = WordsWithFreq[_ip];
									if (wordFreq) {
										let t = [];
										t = wordFreq.Issue;
										t.push(issues[i]);
										wordFreq.count = ++wordFreq.count;
										wordFreq.Issue = t;

									} else {
										WordsWithFreq[_ip] = {
											count: 1,
											Issue: [issues[i]]
										};
									}
								}*/
							}
							
						}
					}
				}
			}
        
		
		// iterating objects on the basis of key to convert into array.
		
        const resultArray = [];
        Object.keys(WordsWithFreq).forEach(key => {
            resultArray.push({ ip: key, count: WordsWithFreq[key].count, Issues: WordsWithFreq[key].Issue, assetStatus:AssetStatus});
        });
		
        // sorting in descending order by count
        const sortedFreq = resultArray.sort((a, b) => {
            if (a.count > b.count) {
                return -1;
            }
            if (a.count < b.count) {
                return 1;
            }
        });
		//console.log(sortedFreq);
        return sortedFreq;
	}
    IssuesByIpArray(issues,IPs){
		const IPsList =[];
		for (let j = 0; j < IPs.length; j++) {
			if(IPsList.indexOf(IPs[j])===-1){
				IPsList.push(IPs[j]);
			}
		}
        const WordsWithFreq = {};
        
        for (let i = 0; i < issues.length; i++) {
			
			if(issues[i].status!='done' && issues[i].status!='Risk Accepted' && issues[i].customfield_10043 && issues[i].customfield_10043!=null){
				for (let j = 0; j < IPsList.length; j++) {
					const ip = IPsList[j];
					var _ip = issues[i].labels.find(x=>x==ip);
					if(_ip)
					{
						let wordFreq = WordsWithFreq[_ip];
						if (wordFreq) {
							let t = [];
							t = wordFreq.Issue;
							t.push(issues[i]);
							wordFreq.count = ++wordFreq.count;
							wordFreq.Issue = t;

						} else {
							WordsWithFreq[_ip] = {
								count: 1,
								Issue: [issues[i]]
							};
						}
					}
				}
			}
        }
        
        // iterating objects on the basis of key to convert into array.
        const resultArray = [];
        Object.keys(WordsWithFreq).forEach(key => {
            resultArray.push({ ip: key, count: WordsWithFreq[key].count, Issues: WordsWithFreq[key].Issue});
        });

        // sorting in descending order by count
        const sortedFreq = resultArray.sort((a, b) => {
            if (a.count > b.count) {
                return -1;
            }
            if (a.count < b.count) {
                return 1;
            }
        });
        return sortedFreq;
    }

    extractOSOfIssuesByIp(issues,IP){
        const WordsWithFreq = {};

        for (let i = 0; i < issues.length; i++) {

            let ip = issues[i].labels.find(x=>x==IP);
            if (issues[i].customfield_10050 && ip) {

                let os = issues[i].customfield_10050;
                return os
            };
        }
    }

    
    
    sliceAssetRiskChartVAlue( slice) {
        let chartValue = slice.dataItem.dataContext.type.toLowerCase();
        return chartValue;
    }
    validate_issue(issuedate, date) {
        if (date.fromdate) {

            let great = new Date("" + date.fromdate + "T00:00:00.000+04:00");
            let issue_date = new Date(issuedate);
            if (issue_date > great) {
                return true
            } else {
                return false;
            }
        }
        else if (date.todate) {

            let less = new Date("" + date.todate + "T00:00:00.000+04:00")
            let issue_date = new Date(issuedate);
            if (issue_date < less) {
                return true
            } else {
                return false;
            }
        }
        else {
            return true
        }
    }
    getDateOnly(date, format = "YYYY-MM-DD") {
        let dateString = '';
        switch (format) {
            case "YYYY-MM-DD":
                let month = date.getMonth() + 1;
                if (month < 10)
                    month = "0" + month.toString();
                let day = date.getDate();
                if (day < 10)
                    day = "0" + day.toString();

                dateString = `${date.getFullYear()}-${month}-${day}`
                break;
            case "DD/MM/YYYY":
                let mon = date.getMonth() + 1;
                if (mon < 10)
                    mon = "0" + mon.toString();
                let da = date.getDate();
                if (da < 10)
                    da = "0" + da.toString();

                dateString = `${da.getFullYear()}-${mon}-${day}`
                break;
            default:
                dateString = date;
                break;
        }
        return dateString;
    }
    nextSunday(date) {
        date = new Date(date);
        let issueDate = date.getDate();// today date
        let dayOfWeek = date.getDay();//week day
        let day_of_next_sunday = 7 - dayOfWeek;

        let lastSunday = date.setDate(issueDate + day_of_next_sunday);
        return new Date(lastSunday);
    }
    dayFirstOfNextMonth(date) {
        date = new Date(date);
        let month = date.getMonth(); // current month
        var year = date.getFullYear();
        let firstdayofmonth = new Date(Date.UTC(year, month + 1, 1));
        return firstdayofmonth;
    }
    dayFirstOfNext3Month(date) {
        date = new Date(date);
        let month = date.getMonth(); // current month
        var year = date.getFullYear();
        let firstdayof3rdmonth = new Date(Date.UTC(year, month + 3, 1));
        return firstdayof3rdmonth;
    }
    getDateOnlyFromIsoDate(isoDate) {

        let date = new Date(isoDate);
        return this.getDateOnly(date);

    }
    xyz(createdDateofFirstIssue, createdDateofLastIssue, date) {
        let newDate = ''
        let firstIssueDate = new Date(this.getDateOnlyFromIsoDate(this.dateTimeZone(createdDateofFirstIssue)));
        let lastIssueDate = new Date(this.getDateOnlyFromIsoDate(this.dateTimeZone(createdDateofLastIssue)));

        if ((lastIssueDate.getFullYear() - firstIssueDate.getFullYear()) >= 1) {
            newDate = this.getDateOnlyFromIsoDate(this.dayFirstOfNext3Month(date))
        } else if ((lastIssueDate.getFullYear() - firstIssueDate.getFullYear()) < 1) {
            if ((new Date(<any>lastIssueDate - <any>firstIssueDate).getMonth()) >= 2) {
                newDate = this.getDateOnlyFromIsoDate(this.dayFirstOfNextMonth(date))
            } else {
                newDate = this.getDateOnlyFromIsoDate(this.nextSunday(date))
            }
        }

        //for ending condition
        if (new Date(newDate) > lastIssueDate)
            newDate = this.getDateOnlyFromIsoDate(lastIssueDate)
        return newDate;
    }
    timelineCal(issues){
        let timelineData = [];
        let tempData = {};
        let tempDate = "";
        let resolvedIssues = [];
        var timelineIssues =issues;
            timelineIssues.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return <any> new Date(a.created) - <any> new Date(b.created);
            });
    
            let MaxResolveDate = timelineIssues[0].resolutiondate;
            for (let i = 1; i < timelineIssues.length; ++i) {
                if (timelineIssues[i].resolutiondate > MaxResolveDate) {
                    MaxResolveDate = timelineIssues[i].resolutiondate;
                }
            }
    
            let Maxdate = timelineIssues[timelineIssues.length - 1].created
            if (timelineIssues[timelineIssues.length - 1].created < MaxResolveDate) {
                Maxdate = MaxResolveDate;
            }
    
            let startDate = timelineIssues[0].created;
            for (var i = 0; i < issues.length; i++) {
                let currentIssue = issues[i];
                this.dateTimeZone(null)
                let createdDate = this.getDateOnlyFromIsoDate(this.dateTimeZone(currentIssue.created));
                let resolutionDate = currentIssue.resolutiondate;
                let resolve_Date = null
                if (resolutionDate) {
                    resolve_Date = this.getDateOnlyFromIsoDate(this.dateTimeZone(issues[i].resolutiondate))
                    var date={};
                    if (this.validate_issue(resolve_Date, date)) {
                        resolvedIssues.push(resolve_Date);
                    }
                    // resolvedIssues.push(this.getDateOnlyFromIsoDate(this.dateTimeZone(issues[i].resolutiondate)));
    
                }
               // var objKeyLength = Object.keys(tempData);
                if (Object.keys(tempData).length <1) {
                    tempDate = createdDate;
                    tempData[createdDate] = {
                        createdCount: 1,
                        resolvedCount: 0,
                        total: 0
                    }
                } else {
                    let temp = "";
                    let flag = true;
                    Object.keys(tempData).forEach(element => {
                        if (new Date(element) >= new Date(createdDate) && flag) {
                            temp = element;
                            tempData[element].createdCount++
                            flag = false;
                        }
                    });
                    if (flag) {
    
                        let timelineDate = []
                        let newdate = this.xyz(startDate, Maxdate, tempDate);
                        timelineDate.push(newdate);
                        tempDate = newdate;
    
                        while (new Date(newdate) < new Date(createdDate)) {
                            newdate = this.xyz(startDate, Maxdate, newdate);
                            tempDate = newdate;
                            timelineDate.push(newdate);
                        }
    
                        timelineDate.forEach(element => {
                            tempData[element] = {
                                createdCount: 0,
                                resolvedCount: 0,
                                total: 0
                            }
                        });
    
                        Object.keys(tempData).forEach(element => {
                            if (new Date(element) >= new Date(createdDate) && flag) {
                                temp = element;
                                tempData[element].createdCount++
                                flag = false;
                            }
                        });
    
                    }
                }
            }
            if (resolvedIssues.length > 0) {
                resolvedIssues.forEach(e => {
                    let flag = true;
                    let temp = "";
                    Object.keys(tempData).forEach((element) => {
                        if (new Date(element) >= new Date(e) && flag) {
                            temp = element;
                            tempData[element].resolvedCount++
                            flag = false;
                        }
                    });
                    if (flag) {
    
                        let timelineDates = [];
                        let newdate = this.xyz(startDate, Maxdate, tempDate);
                        timelineDates.push(newdate);
    
                        while (new Date(newdate) < new Date(e)) {
                            newdate = this.xyz(startDate, Maxdate, newdate);
                            timelineDates.push(newdate);
                        }
    
                        timelineDates.forEach(element => {
                            tempData[element] = {
                                createdCount: 0,
                                resolvedCount: 0,
                                total: 0
                            }
                        });
    
                        Object.keys(tempData).forEach(element => {
                            if (new Date(element) >= new Date(e) && flag) {
                                temp = element;
                                tempData[element].resolvedCount++
                                flag = false;
                            }
                        });
    
                    }
                });
    
            }
            let oldCreatedCount = 0
            let oldResolvedCount = 0
            for (let key in tempData) {
    
                oldCreatedCount = tempData[key]["createdCount"] + oldCreatedCount;
                tempData[key]["createdCount"] = oldCreatedCount;
    
                oldResolvedCount = tempData[key]["resolvedCount"] + oldResolvedCount;
                tempData[key]["resolvedCount"] = oldResolvedCount;
    
                timelineData.push({ createdCount:tempData[key]["createdCount"],resolvedCount:tempData[key]["resolvedCount"], 'date': key.toString() });
            }

            return timelineData;
            // TIMELINE ARRAY ENDS
    }
} 
