import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, map, take, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { User } from "../../classes/user";
import { Ng2IzitoastService } from "ng2-izitoast";
import { GlobalErrorHandler } from '../../_services/error-handler.service';

@Injectable()
export class AuthenticationService {
    

    constructor(private http: HttpClient, private _iziToast: Ng2IzitoastService,private errorService:GlobalErrorHandler) {
    }

    login(user: User): Observable<any> {


        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };

        return this.http.post('/api/login', user, options)
            .pipe(catchError(
                error => of(this.errorService.errorHttp(error))
              ),
        map((response) => {
                // login successful if there's a jwt token in the response

                let user: any = response;
                if (user && user.error && user.error.message == "Email or Password is invalid") {

                    return "user Not Found";

                } else if (user.error){
                    return user;
                }else if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('isUserLoggedIn', 'true');
                    return "UserFound"
                }

            }));
    }

    onlyAuthLogin(user:User){
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };

        return this.http.post('/api/authenticateLogin', user, options)
            .pipe(catchError(
                error => of(this.errorService.errorHttp(error))
              ),
        map((response) => response))
    }

    logout() {
        // remove user from local storage to log user out
        return this.http.post("/api/userLoggedout", null, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
        map((response) => {
                localStorage.removeItem('currentUser');
            }));
    }


    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (currentUser && currentUser.token) {

            let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + currentUser.token });
            return { headers: headers };
        }
    }
}