import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../_services/user.service";
import { Observable } from "rxjs";
import { filter, map } from 'rxjs/operators';
import { BrowserAuthService } from "../_services/browser-auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, private _userService: UserService, private browserAuthService: BrowserAuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this._userService.verify().pipe(map(
            data => {

                if (data.isValid !== false) {

                    // force redirection code 
                    if (data.FA) {

                        if (data.role == "new") {
                            if (state.url.includes('/newuser')) {

                            }
                            else {
                                this._router.navigateByUrl('/newuser');
                            }
                        }
                        else if (data.role == "breg") {


                            if (state.url.includes('/registerBrowser')) {

                            }
                            else {
                                this._router.navigateByUrl('/registerBrowser');
                            }

                            // this.browserAuthService.verifyBrowser().pipe(map(resp=>{
                            //     if(resp!='ok'){
                            //         data.role = "breg"
                            //         if(state.url.includes('/registerBrowser')){

                            //         }
                            //         else{
                            //             this._router.navigateByUrl('/registerBrowser');
                            //         }
                            //     }
                            // }))
                        }
                    }


                    // logged in so return true
                    const expectedRole = route.firstChild.data.expectedRole;
                    const exRole = route.firstChild.data.exRole;
                    // console.log(route.firstChild.data)

                    if (expectedRole !== undefined) {
                        if (expectedRole.includes(data.role) !== true) {
                            this._router.navigate(['/404'], { queryParams: { returnUrl: state.url } });
                            return false;
                        }
                    }

                    if (exRole !== undefined) {
                        //for two factor authentication
                        if (data.FA && exRole.includes('2FA') === true) {
                            return true;
                        }
                        // for cyberteq tester
                        else if (data.tester && exRole.includes(data.tester) === true) {
                            return true;
                        }
                        else if (exRole.includes(data.role) === true) {
                            return true;
                        }
                        else {
                            this._router.navigate(['/404'], { queryParams: { returnUrl: state.url } });
                            return false
                        }
                    }




                    return true;
                }
                // error when verify so redirect to login page with the return url
                this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            },
            error => {
                // error when verify so redirect to login page with the return url
                this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }));
    }
}