import { Injectable, ErrorHandler } from "@angular/core";
import swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
  })
export class GlobalErrorHandler implements ErrorHandler {

    constructor() {
    }

    handleError(error: any): void {
    }

    errorHttp(e) {
        let error = e;
    
        if (error.error === "form tampered with") {
          location.reload();
          // swal('Page expired', 'Please refresh page to continue...', 'error');
          // window.alert('Page expired. Please refresh page to continue...');
        }
        // console.log(error);
      }
}