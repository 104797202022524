
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, map, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { IP } from "../classes/ip";
import { GlobalErrorHandler } from './error-handler.service';


@Injectable()
export class AlertMessagesService {
    constructor(private http: HttpClient,private errorService:GlobalErrorHandler) {
    }

    GetAlertMessages(): Observable<any> {
        return this.http.get("/api/getUserAlertMessages/", this.jwt()).pipe(map((response) => response));
    }
    createAlert(alert): Observable<any> {
        return this.http.post("/api/createAlert/", alert, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }
    updateAlert(alert): Observable<any> {
        return this.http.post("/api/updateAlert/", alert, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }
    DeleteAlert(id): Observable<any> {
        return this.http.get("/api/deleteAlert/" + id, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }
    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            let headers = new HttpHeaders({
                'Authorization': 'Bearer ' + currentUser.token,
                'Content-Type': 'application/json',
            });
            return { headers: headers };
        }
    }

}