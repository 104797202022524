import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { Helpers } from '../../../helpers';
import { JiraService } from '../../../_services/jira.service';
import { GlobalEventsManagerService } from '../../../global-event-manager-service.service';
import { UserService } from '../../../auth/_services/user.service';
import swal from 'sweetalert2';
import jwt from 'jsonwebtoken';
import { IssuesHelper } from '../../../helpers/issuesHelper';
import { IssuesService } from '../../../_services/Issues.service';
import { AlertMessagesService } from '../../../_services/alertMessages.service';
import { Subscription } from 'rxjs';

declare let mLayout: any;
declare let $: any;

@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ["./header-nav.component.css"],
    encapsulation: ViewEncapsulation.None,
    providers: [JiraService, IssuesService, IssuesHelper, AlertMessagesService]
})
export class HeaderNavComponent implements OnInit, AfterViewInit, OnDestroy {
    subs: Subscription[] = [];
    authenticationSubscription: any;
    munitSelected: boolean = false;
    showMunit: boolean = true;
    showNavBar: boolean = false;
    showSuperAdminBar: boolean = false;
    isInProcess = true;
    showManagerData: boolean = false;
    showMemberData: boolean = false;
    logindata: any = null;
    public Alerts: any = [];
    public logging: any = [];
    Secuirtybox: boolean = true;
    public firstname;
    public lastname;
    public email;
    public project;
    public hostName: any;
    public AvgRisk: any | 0;
    public avgRiskValue: any;
    public uniqueIpsCount = 0;
    public alertMessages: any = [];
    public selectedMunits: any = [];
    constructor(private userService: UserService, private alertMessagesService: AlertMessagesService, private issueService: IssuesService, private _jiraService: JiraService, private _globalEventService: GlobalEventsManagerService) {
        this.selectedMunits = [];
        this._jiraService.getAlerts().subscribe(res => {

            this.Alerts = res;

        });
        this._jiraService.getUserLogging().subscribe(res => {
            // this._jiraService.getLogging().subscribe(res => {
            this.logging = res.splice(0,5);
        });
        this.subscribeToAuthentication();
        setInterval(() => {
            this.securityBox();
        }, 300000);

        // this.issueService.getManagerAllIssues().subscribe(issues => {
        //     if (issues) {
        //         this.isInProcess = false;
        //         if (issues.Risk >= 0 && issues.Risk <= 27) {
        //             $('.avgRisk').addClass('lowRisk');
        //             this.AvgRisk = "Low";
        //         }
        //         else if (issues.Risk >= 28 && issues.Risk <= 63) {
        //             $('.avgRisk').addClass('mediumRisk');
        //             this.AvgRisk = "Medium";
        //         }
        //         else if (issues.Risk >= 64 && issues.Risk < 90) {
        //             $('.avgRisk').addClass('highRisk');
        //             this.AvgRisk = "High";
        //         }
        //         this.avgRiskValue = issues.Risk.toFixed(2);
        //         this.uniqueIpsCount = issues.UniqueIps.length;

        //     }
        // });
        setTimeout(() => {
            this.getAlertMessages();
        }, 5000);


    }
    getAlertMessages() {
        this.alertMessagesService.GetAlertMessages().subscribe(messages => {
            this.alertMessages = [];
            for (let i = 0; i < messages.length; i++) {
                let valid: any = new Date(messages[i].ToDate);
                let now: any = new Date();

                let isUserEligible = false;
                for (let j = 0; j < messages[i].users.length; j++) {
                    let element = messages[i].users[j];
                    if (element === this.email) {
                        isUserEligible = true;
                    }

                }
                if (valid > now && this.project === messages[i].project || valid > now && isUserEligible) {
                    this.alertMessages.push(messages[i]);
                }
            }
        });
    }


    securityBox() {
        this._jiraService.getSecurityBox().subscribe(res => {
            if (res == "false") {
                this.Secuirtybox = false;
            }
            else {
                this.hostName = res.hostName;
                this.Secuirtybox = true;
            }
        });
    }

    ngOnInit() {
        this._globalEventService.selectedMunitsEmitter.subscribe(resp => {
            if (resp) {
                this.munitSelected = false;
                this.selectedMunits = [];
                this.selectedMunits = resp;
                this.munitSelected = true;
                if (this.selectedMunits.length > 1) {
                    this.showMunit = false;
                }
                if (this.selectedMunits.length == 1 && this.selectedMunits[0] === "ALL") {
                    this.munitSelected = false;
                }
            }
        });
        this._globalEventService.alertMessagesEmitter.subscribe(shouldGet => {
            if (shouldGet === "true") {
                this.getAlertMessages();
            }
        });
    }
    ngAfterViewInit() {
        mLayout.initHeader();

        this._globalEventService.companyRiskEmitter.subscribe(risk => {
            if (risk) {
                this.isInProcess = false;
                if (risk >= 0 && risk <= 27) {
                    $('.avgRisk').addClass('lowRisk');
                    this.AvgRisk = "Low";
                }
                else if (risk >= 28 && risk <= 63) {
                    $('.avgRisk').addClass('mediumRisk');
                    this.AvgRisk = "Medium";
                }
                else if (risk >= 64 && risk < 90) {
                    $('.avgRisk').addClass('highRisk');
                    this.AvgRisk = "High";
                }
                this.avgRiskValue = risk.toFixed(2);
            }
        });

    }

    subscribeToAuthentication() {
        if (localStorage.getItem('isUserLoggedIn') == 'true') {
            let currentuser = localStorage.getItem('currentUser');
            let Fetchtoken = JSON.parse(currentuser);

            let Token = Fetchtoken.token;

            let authenticationSubscription = this.userService.getUserInformation(Token).subscribe(res => {

                this.firstname = res.firstname;
                this.lastname = res.lastname;
                this.email = res.email;
                this.project = res.projectName;
                if (res.role == 'Super') {

                    this._globalEventService.showSuperAdminBar(true);
                    this._globalEventService.showSuperAdminBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showSuperAdminBar = mode;
                        }
                    });
                } else if (res.role == 'Admin') {

                    this._globalEventService.showNavBar(true);
                    this._globalEventService.showNavBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showNavBar = mode;
                        }
                    });
                } else if (res.role == 'Manager') {

                    this._globalEventService.showManagerData(true);
                    let obs = this._globalEventService.showManegerEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showManagerData = mode;
                        }
                        this.subs.push(obs);

                    });

                } else if (res.role == 'Member') {

                    this._globalEventService.showMemberData(true);
                    this._globalEventService.showMemberEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showMemberData = mode;
                        }
                    });
                }
                this.subs.push(authenticationSubscription);
            });
        }



        // this._globalEventService.showNavBar(true);
        // this._globalEventService.showNavBarEmitter.subscribe((mode:any)=>{
        //         // mode will be null the first time it is created, so you need to igonore it when null
        //         if (mode !== null) {
        //           this.showNavBar = mode;
        //         }
        //     });

        // this._globalEventService.loginDataEmitter.subscribe((loginData:any)=>{

        //         // mode will be null the first time it is created, so you need to igonore it when null
        //         if (loginData !== null) {

        //           this.logindata = loginData;
        //         }
        //     });
    }

    alertModel(source: any, date: any, description: any, recommendation: any, tags: any) {

        let html = "<h5><strong>Source</strong></h5>\
          <p>"+ source + "</p>\
          <h5><strong>Date</strong></h5>\
          <p>"+ date + "</p>\
          <h5><strong>Description</strong></h5>\
          <p>"+ description + "</p>\
          <h5><strong>Recommendation</strong></h5>\
          <p>"+ recommendation + "</p>\
          <h5><strong>Tags</strong></h5>\
          <p>"+ tags + "</p>"
        $(".modal-body").html(html);
        $("#myModal").modal('show');
    }
    ngOnDestroy(): void {
        this.subs.forEach(s => {
            if (s) {
                s.unsubscribe()
            }
        });
        // this.authenticationSubscription.unsubscribe();
    }

}
