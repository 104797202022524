import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { Helpers } from '../../../helpers';
import { GlobalEventsManagerService } from '../../../global-event-manager-service.service';
import { UserService } from '../../../auth/_services/user.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
declare let mLayout: any;
declare var $: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit, OnDestroy {
    dashboardMenuLinks: boolean = false;
    authenticationSubscription: any;
    LoggedInUserEmail: any;
    showNavBar: boolean = false;
    showManagerData: boolean = false;
    showMemberData: boolean = false;
    showPentesterData: boolean = false;
    subs: Subscription[] = [];
    showSuperAdminBar: boolean = false;
    showTesterBar: Boolean = false;


    constructor(private router: Router,private userService: UserService, private _globalEventService: GlobalEventsManagerService) {
        this.subscribeToAuthentication();
    }
    ngOnInit() {
        $('#body').addClass('m-brand--minimize m-aside-left--minimize');
        if(this.router.url ==='/index'){
            this.dashboardMenuLinks = true;
        }else{
            this.dashboardMenuLinks = false;
        }
    }
    ngAfterViewInit() {

        mLayout.initAside();

    }

    subscribeToAuthentication() {
        if (localStorage.getItem('isUserLoggedIn') == 'true') {
            // var currentuser = localStorage.getItem('currentUser');
            // var Fetchtoken = JSON.parse(currentuser);

            // var Token = Fetchtoken.token;

            let authenticationSubscription = this.userService.verify().subscribe(res => {

                this.LoggedInUserEmail = res.email;
                if (res.tester) {
    
                    this._globalEventService.showTesterBar(true);
                    this._globalEventService.showTesterBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showTesterBar = mode;
                        }
                    });
                } 
                if (res.role == 'Super') {

                    this._globalEventService.showSuperAdminBar(true);
                    this._globalEventService.showSuperAdminBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showSuperAdminBar = mode;
                        }
                    });
                } else if (res.role == 'Admin') {

                    this._globalEventService.showNavBar(true);
                    this._globalEventService.showNavBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showNavBar = mode;
                        }
                    });
                } else if (res.role == 'Manager') {

                    this._globalEventService.showManagerData(true);

                    let obs = this._globalEventService.showManegerEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showManagerData = mode;
                        }
                        this.subs.push(obs);
                    });

                } else if (res.role == 'Member') {

                    this._globalEventService.showMemberData(true);
                    let obs = this._globalEventService.showMemberEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showMemberData = mode;
                        }
                        this.subs.push(obs);
                    });
                }
                else if (res.role == 'Pentester') {

                    this._globalEventService.showPentesterData(true);
                    this._globalEventService.showPentesterEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showPentesterData = mode;
                        }
                    });
                }
                this.subs.push(authenticationSubscription)
            });
        }




    }

    ngOnDestroy(): void {
        this.subs.forEach(s => {
            if (s) {
                s.unsubscribe()
            }
        });

        // this.authenticationSubscription.unsubscribe();
    }

}